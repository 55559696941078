/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorrectiveActionSelectionDto
 */
export interface CorrectiveActionSelectionDto {
    /**
     * 
     * @type {number}
     * @memberof CorrectiveActionSelectionDto
     */
    responseId?: number;
    /**
     * 
     * @type {number}
     * @memberof CorrectiveActionSelectionDto
     */
    correctiveActionId?: number;
}

/**
 * Check if a given object implements the CorrectiveActionSelectionDto interface.
 */
export function instanceOfCorrectiveActionSelectionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorrectiveActionSelectionDtoFromJSON(json: any): CorrectiveActionSelectionDto {
    return CorrectiveActionSelectionDtoFromJSONTyped(json, false);
}

export function CorrectiveActionSelectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionSelectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseId': !exists(json, 'responseId') ? undefined : json['responseId'],
        'correctiveActionId': !exists(json, 'correctiveActionId') ? undefined : json['correctiveActionId'],
    };
}

export function CorrectiveActionSelectionDtoToJSON(value?: CorrectiveActionSelectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseId': value.responseId,
        'correctiveActionId': value.correctiveActionId,
    };
}

