import User from "@/models/application/user";
import { defineStore } from "pinia";
import { minTime } from "date-fns";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        lastAuthenticatedDate: new Date(minTime),
        userDetails: new User(),
        autoSignOutFlag: false
    }),
    getters: {
        lastAuthenticated: (state) => state.lastAuthenticatedDate,
        user: (state) => state.userDetails,
        signedOutBySystem: (state) =>  (window.localStorage.getItem("autoLogOut") == "true") || state.autoSignOutFlag == true 
    },
    actions:{
        autoSignOut(){
            this.autoSignOutFlag = true;
            window.localStorage.setItem("autoLogOut", "true")
        },
        manualSignOut(){
            this.autoSignOutFlag = false;
            window.localStorage.setItem("autoLogOut", "false")
        }
    }
});
