/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreLink
 */
export interface StoreLink {
    /**
     * 
     * @type {number}
     * @memberof StoreLink
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreLink
     */
    employeeId?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreLink
     */
    storeId?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreLink
     */
    storeName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StoreLink
     */
    lastUpdated?: Date;
}

/**
 * Check if a given object implements the StoreLink interface.
 */
export function instanceOfStoreLink(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StoreLinkFromJSON(json: any): StoreLink {
    return StoreLinkFromJSONTyped(json, false);
}

export function StoreLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'storeId': !exists(json, 'storeId') ? undefined : json['storeId'],
        'storeName': !exists(json, 'storeName') ? undefined : json['storeName'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
    };
}

export function StoreLinkToJSON(value?: StoreLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'employeeId': value.employeeId,
        'storeId': value.storeId,
        'storeName': value.storeName,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
    };
}

