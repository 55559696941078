/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplementaryQuestionDto
 */
export interface SupplementaryQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof SupplementaryQuestionDto
     */
    supplementaryQuestionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplementaryQuestionDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementaryQuestionDto
     */
    help?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementaryQuestionDto
     */
    expectedResponseType?: string | null;
}

/**
 * Check if a given object implements the SupplementaryQuestionDto interface.
 */
export function instanceOfSupplementaryQuestionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementaryQuestionDtoFromJSON(json: any): SupplementaryQuestionDto {
    return SupplementaryQuestionDtoFromJSONTyped(json, false);
}

export function SupplementaryQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementaryQuestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supplementaryQuestionId': !exists(json, 'supplementaryQuestionId') ? undefined : json['supplementaryQuestionId'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'help': !exists(json, 'help') ? undefined : json['help'],
        'expectedResponseType': !exists(json, 'expectedResponseType') ? undefined : json['expectedResponseType'],
    };
}

export function SupplementaryQuestionDtoToJSON(value?: SupplementaryQuestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplementaryQuestionId': value.supplementaryQuestionId,
        'text': value.text,
        'help': value.help,
        'expectedResponseType': value.expectedResponseType,
    };
}

