/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackDto } from './PackDto';
import {
    PackDtoFromJSON,
    PackDtoFromJSONTyped,
    PackDtoToJSON,
} from './PackDto';

/**
 * 
 * @export
 * @interface GetResponsePacksViewModel
 */
export interface GetResponsePacksViewModel {
    /**
     * 
     * @type {Array<PackDto>}
     * @memberof GetResponsePacksViewModel
     */
    packs?: Array<PackDto> | null;
}

/**
 * Check if a given object implements the GetResponsePacksViewModel interface.
 */
export function instanceOfGetResponsePacksViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetResponsePacksViewModelFromJSON(json: any): GetResponsePacksViewModel {
    return GetResponsePacksViewModelFromJSONTyped(json, false);
}

export function GetResponsePacksViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResponsePacksViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packs': !exists(json, 'packs') ? undefined : (json['packs'] === null ? null : (json['packs'] as Array<any>).map(PackDtoFromJSON)),
    };
}

export function GetResponsePacksViewModelToJSON(value?: GetResponsePacksViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packs': value.packs === undefined ? undefined : (value.packs === null ? null : (value.packs as Array<any>).map(PackDtoToJSON)),
    };
}

