/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CorrectiveActionSelectionDto } from './CorrectiveActionSelectionDto';
import {
    CorrectiveActionSelectionDtoFromJSON,
    CorrectiveActionSelectionDtoFromJSONTyped,
    CorrectiveActionSelectionDtoToJSON,
} from './CorrectiveActionSelectionDto';

/**
 * 
 * @export
 * @interface SetSessionCorrectiveActionSelections
 */
export interface SetSessionCorrectiveActionSelections {
    /**
     * 
     * @type {number}
     * @memberof SetSessionCorrectiveActionSelections
     */
    packResponseId?: number;
    /**
     * 
     * @type {Array<CorrectiveActionSelectionDto>}
     * @memberof SetSessionCorrectiveActionSelections
     */
    correctiveActions?: Array<CorrectiveActionSelectionDto> | null;
}

/**
 * Check if a given object implements the SetSessionCorrectiveActionSelections interface.
 */
export function instanceOfSetSessionCorrectiveActionSelections(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SetSessionCorrectiveActionSelectionsFromJSON(json: any): SetSessionCorrectiveActionSelections {
    return SetSessionCorrectiveActionSelectionsFromJSONTyped(json, false);
}

export function SetSessionCorrectiveActionSelectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetSessionCorrectiveActionSelections {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packResponseId': !exists(json, 'packResponseId') ? undefined : json['packResponseId'],
        'correctiveActions': !exists(json, 'correctiveActions') ? undefined : (json['correctiveActions'] === null ? null : (json['correctiveActions'] as Array<any>).map(CorrectiveActionSelectionDtoFromJSON)),
    };
}

export function SetSessionCorrectiveActionSelectionsToJSON(value?: SetSessionCorrectiveActionSelections | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packResponseId': value.packResponseId,
        'correctiveActions': value.correctiveActions === undefined ? undefined : (value.correctiveActions === null ? null : (value.correctiveActions as Array<any>).map(CorrectiveActionSelectionDtoToJSON)),
    };
}

