import type { UrlParams } from "@vueuse/core";

interface DynamicObject { [key:string] : any}

function routeToInt(route : UrlParams) {
    let propObj : DynamicObject = {};
    for(const [key,value] of Object.entries(route.params)){
        propObj[key] = stringToInt(value);
    }
    return propObj;
}

function stringToInt(val : string){
    let num = Number.parseInt(val, 10);
    if (Number.isNaN(num)) {
      return val;
    }
    return num;
}


export {routeToInt};