/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AreaManagerSignOffCommand
 */
export interface AreaManagerSignOffCommand {
    /**
     * 
     * @type {number}
     * @memberof AreaManagerSignOffCommand
     */
    packId?: number;
}

/**
 * Check if a given object implements the AreaManagerSignOffCommand interface.
 */
export function instanceOfAreaManagerSignOffCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AreaManagerSignOffCommandFromJSON(json: any): AreaManagerSignOffCommand {
    return AreaManagerSignOffCommandFromJSONTyped(json, false);
}

export function AreaManagerSignOffCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaManagerSignOffCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packId': !exists(json, 'packId') ? undefined : json['packId'],
    };
}

export function AreaManagerSignOffCommandToJSON(value?: AreaManagerSignOffCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packId': value.packId,
    };
}

