// API
import { apiFactory } from "@/api/factories/apiFactory";

// Services annd Stores
import { useApplicationStore } from "@/stores/applicationStore";
import type { Profile } from "@/models/application/profile";

class ApplicationService {
    private readonly _applicationApi = apiFactory.useProfileApi();
    private readonly _applicationStore = useApplicationStore();

    async initialiseApplication() {
        const profile: Profile | undefined = (await this._applicationApi.getProfile()).response;
        this._applicationStore.profileDetails = profile ?? null;
    }

    /** Get Profile */
    public async getProfile() {
        if (this._applicationStore.profile == null) {
            await this.initialiseApplication();
        }

        return this._applicationStore.profile;
    }
}

export default ApplicationService;
