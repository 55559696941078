/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PositionLink
 */
export interface PositionLink {
    /**
     * 
     * @type {number}
     * @memberof PositionLink
     */
    employeeId?: number;
    /**
     * 
     * @type {number}
     * @memberof PositionLink
     */
    positionTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof PositionLink
     */
    positionTypeDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PositionLink
     */
    lastUpdated?: Date;
}

/**
 * Check if a given object implements the PositionLink interface.
 */
export function instanceOfPositionLink(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PositionLinkFromJSON(json: any): PositionLink {
    return PositionLinkFromJSONTyped(json, false);
}

export function PositionLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositionLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'positionTypeId': !exists(json, 'positionTypeId') ? undefined : json['positionTypeId'],
        'positionTypeDescription': !exists(json, 'positionTypeDescription') ? undefined : json['positionTypeDescription'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
    };
}

export function PositionLinkToJSON(value?: PositionLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'positionTypeId': value.positionTypeId,
        'positionTypeDescription': value.positionTypeDescription,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
    };
}

