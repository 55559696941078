import type { MenuItem } from "@/models/application/menuItem";
import type { Profile } from "@/models/application/profile";

import { defineStore } from "pinia";

export const useApplicationStore = defineStore({
    id: "application",
    state: () => ({
        menuItems: null as MenuItem[] | null,
        profileDetails: null as Profile | null
    }),
    getters: {
        applicationMenu: (state) => state.menuItems,
        profile: (state) => state.profileDetails
    }

});