
import type { FetchParams, Middleware, ResponseContext } from "@/HttpClient"; 
import type ProblemDetails from "./ProblemDetails"
import AuthService from '@/services/application/authService'
import {useAuthStore} from '@/stores/authStore'
import {useRouter} from 'vue-router'

export class ApiMiddleware implements Middleware {
  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    return {
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers
        }),
      },
    };
  }

  public async post(context: ResponseContext): Promise<Response | void> {
    
    if (!context.response.ok) {
      if(context.response.status == 401)
      {
        this.signOut()
      }

      let error =  JSON.parse(await context.response.text()) || { message: context.response.statusText, status: context.response.status } as ProblemDetails;

      return Promise.reject(error);
    }
    return Promise.resolve(context.response);
  }

  public async get(context:ResponseContext) : Promise<Response | void> {
    if (!context.response.ok) {
      if(context.response.status == 401)
      {
        this.signOut() 
      }

      let error =  JSON.parse(await context.response.text()) || { message: context.response.statusText, status: context.response.status } as ProblemDetails;

      return Promise.reject(error);
    }
    return Promise.resolve(context.response);
  }

  public async put(context:ResponseContext) : Promise<Response | void> {
    if (!context.response.ok) {
      if(context.response.status == 401)
      {
        this.signOut()
      }

      let error =  JSON.parse(await context.response.text()) || { message: context.response.statusText, status: context.response.status } as ProblemDetails;
      
      return Promise.reject(error);
    }
    return Promise.resolve(context.response);
  }

  public async delete(context:ResponseContext) : Promise<Response | void> {
    if (!context.response.ok) {
      if(context.response.status == 401)
      {
        this.signOut()
      }

      let error =  JSON.parse(await context.response.text()) || { message: context.response.statusText, status: context.response.status } as ProblemDetails;
      
      return Promise.reject(error);
    }
    return Promise.resolve(context.response);
  }

  signOut() {
    const authStore = useAuthStore();
    if(!window.location.href.includes('/signedOut') && authStore.user.isAuthenticated){
      authStore.autoSignOut();
      window.location.href = '/signedOut'
    }
  }
}

