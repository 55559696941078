/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPossibleStoresViewModel,
  SetCurrentStoreCommand,
} from '../models/index';
import {
    GetPossibleStoresViewModelFromJSON,
    GetPossibleStoresViewModelToJSON,
    SetCurrentStoreCommandFromJSON,
    SetCurrentStoreCommandToJSON,
} from '../models/index';

export interface ApiStoreSelectorPutRequest {
    setCurrentStoreCommand?: SetCurrentStoreCommand;
}

/**
 * 
 */
export class StoreSelectorApi extends runtime.BaseAPI {

    /**
     */
    async apiStoreSelectorCheckCurrentStoreGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StoreSelector/checkCurrentStore`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiStoreSelectorCheckCurrentStoreGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiStoreSelectorCheckCurrentStoreGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStoreSelectorGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPossibleStoresViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/StoreSelector`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPossibleStoresViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiStoreSelectorGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPossibleStoresViewModel> {
        const response = await this.apiStoreSelectorGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStoreSelectorPutRaw(requestParameters: ApiStoreSelectorPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/StoreSelector`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetCurrentStoreCommandToJSON(requestParameters.setCurrentStoreCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiStoreSelectorPut(requestParameters: ApiStoreSelectorPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiStoreSelectorPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
