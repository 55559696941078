export function debounce<T extends (...args: any[]) => any>(fn: T, wait: number): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout> | null = null;
  
    return function debouncedFunction(this: any, ...args: Parameters<T>): void {
      if (timer) {
        clearTimeout(timer); // clear any pre-existing timer
      }
  
      const context = this; // get the current context
  
      timer = setTimeout(() => {
        fn.apply(context, args); // call the function if time expires
      }, wait);
    };
  }
  