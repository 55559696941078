/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AreaManagerSignOffCommand,
  GetCorrectiveActionsViewModel,
  GetSessionCorrectiveActionsQueryVm,
  GetSignOffPackByIdViewModel,
  GetSignOffPacksViewModel,
  SetSessionCorrectiveActionSelections,
  StoreManagerSignOffCommand,
} from '../models/index';
import {
    AreaManagerSignOffCommandFromJSON,
    AreaManagerSignOffCommandToJSON,
    GetCorrectiveActionsViewModelFromJSON,
    GetCorrectiveActionsViewModelToJSON,
    GetSessionCorrectiveActionsQueryVmFromJSON,
    GetSessionCorrectiveActionsQueryVmToJSON,
    GetSignOffPackByIdViewModelFromJSON,
    GetSignOffPackByIdViewModelToJSON,
    GetSignOffPacksViewModelFromJSON,
    GetSignOffPacksViewModelToJSON,
    SetSessionCorrectiveActionSelectionsFromJSON,
    SetSessionCorrectiveActionSelectionsToJSON,
    StoreManagerSignOffCommandFromJSON,
    StoreManagerSignOffCommandToJSON,
} from '../models/index';

export interface ApiSignOffAreaManagerPutRequest {
    areaManagerSignOffCommand?: AreaManagerSignOffCommand;
}

export interface ApiSignOffCachedCorrectiveActionsPackResponseIdGetRequest {
    packResponseId: number;
}

export interface ApiSignOffCachedCorrectiveActionsPostRequest {
    setSessionCorrectiveActionSelections?: SetSessionCorrectiveActionSelections;
}

export interface ApiSignOffIdGetRequest {
    id: number;
}

export interface ApiSignOffStoreManagerPutRequest {
    storeManagerSignOffCommand?: StoreManagerSignOffCommand;
}

/**
 * 
 */
export class SignOffApi extends runtime.BaseAPI {

    /**
     */
    async apiSignOffAreaManagerPutRaw(requestParameters: ApiSignOffAreaManagerPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/SignOff/areaManager`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AreaManagerSignOffCommandToJSON(requestParameters.areaManagerSignOffCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiSignOffAreaManagerPut(requestParameters: ApiSignOffAreaManagerPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiSignOffAreaManagerPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffCachedCorrectiveActionsPackResponseIdGetRaw(requestParameters: ApiSignOffCachedCorrectiveActionsPackResponseIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSessionCorrectiveActionsQueryVm>> {
        if (requestParameters.packResponseId === null || requestParameters.packResponseId === undefined) {
            throw new runtime.RequiredError('packResponseId','Required parameter requestParameters.packResponseId was null or undefined when calling apiSignOffCachedCorrectiveActionsPackResponseIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SignOff/cachedCorrectiveActions/{packResponseId}`.replace(`{${"packResponseId"}}`, encodeURIComponent(String(requestParameters.packResponseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSessionCorrectiveActionsQueryVmFromJSON(jsonValue));
    }

    /**
     */
    async apiSignOffCachedCorrectiveActionsPackResponseIdGet(requestParameters: ApiSignOffCachedCorrectiveActionsPackResponseIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSessionCorrectiveActionsQueryVm> {
        const response = await this.apiSignOffCachedCorrectiveActionsPackResponseIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffCachedCorrectiveActionsPostRaw(requestParameters: ApiSignOffCachedCorrectiveActionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/SignOff/cachedCorrectiveActions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSessionCorrectiveActionSelectionsToJSON(requestParameters.setSessionCorrectiveActionSelections),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiSignOffCachedCorrectiveActionsPost(requestParameters: ApiSignOffCachedCorrectiveActionsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiSignOffCachedCorrectiveActionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffCorrectiveActionsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCorrectiveActionsViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SignOff/correctiveActions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCorrectiveActionsViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiSignOffCorrectiveActionsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCorrectiveActionsViewModel> {
        const response = await this.apiSignOffCorrectiveActionsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSignOffPacksViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SignOff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignOffPacksViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiSignOffGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSignOffPacksViewModel> {
        const response = await this.apiSignOffGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffIdGetRaw(requestParameters: ApiSignOffIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSignOffPackByIdViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSignOffIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/SignOff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignOffPackByIdViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiSignOffIdGet(requestParameters: ApiSignOffIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSignOffPackByIdViewModel> {
        const response = await this.apiSignOffIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSignOffStoreManagerPutRaw(requestParameters: ApiSignOffStoreManagerPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/SignOff/storeManager`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StoreManagerSignOffCommandToJSON(requestParameters.storeManagerSignOffCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiSignOffStoreManagerPut(requestParameters: ApiSignOffStoreManagerPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiSignOffStoreManagerPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
