<template>
    <div class="text-end" v-if="isBatch && missedCount > 1">{{completedCount + 1}} / {{ missedCount }} </div>
    <div v-if="hasHelp" class="help-box" :class="helpStyle" v-html="props.check.helpText" />
    <div class="missed-response-form bg-danger bg-opacity-25">
        <label class="missed-time-label">Missed Check</label>
        <span class="missed-time"> Due by <b>{{ formatTime(check.dueDateTime) }}</b>  on <b>{{ formatDate(check.dueDateTime) }}</b> </span>

        <label class="reason-label">Reason</label>
        <select class="reason-select" v-model="selectedExplanation">
            <option v-for="reason in filteredReasons" :key="reason.explanationId" :value="reason.explanationId">{{ reason.explanation }}</option>
        </select>

    </div>
</template>

<script setup lang="ts">
    import type { PackCheckDto } from '@/HttpClient';
    import { formatDate, formatTime } from '@/utils/dateFormat'
    import { ref, watch, computed } from 'vue'

    import { ResponsePackApi, type MissedCheckExplanationDto } from '@/HttpClient';
    import { ApiConfig } from '@/config/apiConfig';
    import { mapToHelpStyle } from '@/utils/styleMaps'
    const requestApi = new ResponsePackApi(new ApiConfig());

    const props = defineProps<{
        check: PackCheckDto,
        reset: number,
        isBatch: boolean,
        missedCount: number,
        completedCount: number
    }>()

    let selectedExplanation = ref(0);

    let reasons = ref<Array<MissedCheckExplanationDto>>([])

    const helpStyle = computed(() => mapToHelpStyle(props.check.colour));

    const hasHelp = computed(() => (props.check.helpText != ""));

    const emit = defineEmits<{
        (e: 'explanation-changed', data: number): void
    }>();

    watch(selectedExplanation, () => {
        emit('explanation-changed', selectedExplanation.value)
    })

    requestApi.apiResponsePackMissedGet()
        .then(response => {
            reasons.value = response.explanations ?? []
        })

    const filteredReasons = computed(() =>
        reasons.value
            .filter(x => (x.programmaticKey === "NA_ONLY" && props.check.allowNotApplicable) || (x.programmaticKey !== 'NA_ONLY' && x.programmaticKey !== 'MISSED'))
    )

    watch(() => props.reset, () => selectedExplanation.value = 0)

</script>

<style lang="scss">
    .missed-response-form {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: max-content+2em 1em;
        grid-gap: 1em;
        grid-template-areas:
            "label1 data1" "label2 data2";
        padding: 1em;
        border-radius: 0.3rem;

        & > .missed-time-label {
            grid-area: label1;
        }

        & > .missed-time {
            grid-area: data1;
        }

        & > .reason-label {
            grid-area: label2;
        }

        & > .reason-select {
            grid-area: data2;
            width: 100%;
        }
    }

    .missed-response-form > .missed-time-label {
        grid-area: label1;
    }

    .missed-response-form > .missed-time {
        grid-area: data1;
    }

    .missed-response-form > .reason-label {
        grid-area: label2;
    }

    .missed-response-form > .reason-select {
        grid-area: data2;
    }

    .help-box {
        padding: 0.5em;
        margin-bottom: 0.5em;
        border-radius: 0.3em;
    }
</style>