/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationUser,
  GetCurrentStoreInfoViewModel,
} from '../models/index';
import {
    ApplicationUserFromJSON,
    ApplicationUserToJSON,
    GetCurrentStoreInfoViewModelFromJSON,
    GetCurrentStoreInfoViewModelToJSON,
} from '../models/index';

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiUserCurrentStoreGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/User/currentStore`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiUserCurrentStoreGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiUserCurrentStoreGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserCurrentStoreInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCurrentStoreInfoViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/User/currentStoreInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentStoreInfoViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiUserCurrentStoreInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCurrentStoreInfoViewModel> {
        const response = await this.apiUserCurrentStoreInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/User`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationUserFromJSON(jsonValue));
    }

    /**
     */
    async apiUserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationUser> {
        const response = await this.apiUserGetRaw(initOverrides);
        return await response.value();
    }

}
