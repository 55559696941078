import { httpClient } from "@/api/httpClient";
import User from "@/models/application/user";
import apiResponse from "@/models/base/apiResponse";

export default class {
  private readonly resource = "/api/user";

  async getUser () : Promise<apiResponse<User>> {
    const request = new apiResponse<User>(async () => await httpClient.get(`${this.resource}`));
    await request.ready;

    return request;
  }
}
