<script setup lang="ts">import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/authStore";

const router = useRouter();

const authStore = useAuthStore();

function redirectToLandingPage () {
  authStore.manualSignOut();
  router.push({ name: "landingPage" });
}
</script>

<template>
  <div class="container py-4">
    <div class="p-5 mb-4 bg-light rounded-3 text-center">
      <h1 class="display-5 fw-bold">
        Signed Out
      </h1>
      <p class="col-md fs-4">
        <span v-if="authStore.signedOutBySystem">
          You have been inactive for more than 10 minutes and have therefore been signed out, please sign back in again to continue.
        </span>
        <span v-else>
          You have successfully signed out
        </span>
      </p>
      <button class="btn btn-primary btn-lg" @click="redirectToLandingPage()">
        Sign back in
      </button>
    </div>
  </div>
</template>
