/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActiveAreaManager } from './ActiveAreaManager';
import {
    ActiveAreaManagerFromJSON,
    ActiveAreaManagerFromJSONTyped,
    ActiveAreaManagerToJSON,
} from './ActiveAreaManager';
import type { PositionLink } from './PositionLink';
import {
    PositionLinkFromJSON,
    PositionLinkFromJSONTyped,
    PositionLinkToJSON,
} from './PositionLink';
import type { SkillLink } from './SkillLink';
import {
    SkillLinkFromJSON,
    SkillLinkFromJSONTyped,
    SkillLinkToJSON,
} from './SkillLink';
import type { StoreLink } from './StoreLink';
import {
    StoreLinkFromJSON,
    StoreLinkFromJSONTyped,
    StoreLinkToJSON,
} from './StoreLink';

/**
 * 
 * @export
 * @interface CachedUserAccessDto
 */
export interface CachedUserAccessDto {
    /**
     * 
     * @type {ActiveAreaManager}
     * @memberof CachedUserAccessDto
     */
    areaManagerAccess?: ActiveAreaManager;
    /**
     * 
     * @type {PositionLink}
     * @memberof CachedUserAccessDto
     */
    position?: PositionLink;
    /**
     * 
     * @type {Array<SkillLink>}
     * @memberof CachedUserAccessDto
     */
    skills?: Array<SkillLink> | null;
    /**
     * 
     * @type {Array<StoreLink>}
     * @memberof CachedUserAccessDto
     */
    stores?: Array<StoreLink> | null;
}

/**
 * Check if a given object implements the CachedUserAccessDto interface.
 */
export function instanceOfCachedUserAccessDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CachedUserAccessDtoFromJSON(json: any): CachedUserAccessDto {
    return CachedUserAccessDtoFromJSONTyped(json, false);
}

export function CachedUserAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CachedUserAccessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areaManagerAccess': !exists(json, 'areaManagerAccess') ? undefined : ActiveAreaManagerFromJSON(json['areaManagerAccess']),
        'position': !exists(json, 'position') ? undefined : PositionLinkFromJSON(json['position']),
        'skills': !exists(json, 'skills') ? undefined : (json['skills'] === null ? null : (json['skills'] as Array<any>).map(SkillLinkFromJSON)),
        'stores': !exists(json, 'stores') ? undefined : (json['stores'] === null ? null : (json['stores'] as Array<any>).map(StoreLinkFromJSON)),
    };
}

export function CachedUserAccessDtoToJSON(value?: CachedUserAccessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'areaManagerAccess': ActiveAreaManagerToJSON(value.areaManagerAccess),
        'position': PositionLinkToJSON(value.position),
        'skills': value.skills === undefined ? undefined : (value.skills === null ? null : (value.skills as Array<any>).map(SkillLinkToJSON)),
        'stores': value.stores === undefined ? undefined : (value.stores === null ? null : (value.stores as Array<any>).map(StoreLinkToJSON)),
    };
}

