import type { Policy } from "@/enums/Policy";

export default class User {
    name: string;
    jobTitle: string;
    isAuthenticated: boolean;
    permissions: string[];

    constructor(name = "", isAuthenticated = false, permissions: string[] = [], jobTitle = "Employee") {
        this.name = name;
        this.jobTitle = jobTitle;
        this.isAuthenticated = isAuthenticated;
        this.permissions = permissions;
    }

    meetsPolicies(policies: Policy[]) {
        return (
            policies.length == 0 || policies.some((p) => this.permissions.includes(p))
        );
    }

    meetsPolicy(policy: Policy) {
        return this.meetsPolicies([policy]);
    }
}
