import { httpClient } from "@/api/httpClient";
import apiResponse from "@/models/base/apiResponse";

export default class {
  private readonly resource = "/api/healthCheck";

  public async getHealthCheck () : Promise<apiResponse<boolean>> {
    const response = new apiResponse<boolean>(await httpClient.get(this.resource));
    await response.ready;
    return response;
  }
}
