﻿<template>
    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <BootstrapIconComponent @click="onToggleSidebar" icon="list" :height="32" :width="32"
                                    class="toggle-sidebar-btn" />
            <a href="#!" class="logo d-flex align-items-center">
                <img src="@/assets/logos/logo.png" alt="logo" />
            </a>
        </div>

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">

                <!-- Helper button icon for when help articles are integrated -->
                <li class="nav-item d-none">
                    <a class="nav-link nav-icon" href="#!" title="Help Articles">
                        <font-awesome-icon icon="fa-regular fa-circle-question" />
                    </a>
                </li>

                <!-- Teleport site to Show whether notifications have been blocked -->
                <li class="nav-item dropdown" id="teleport-landing">

                </li>

                <li class="nav-item dropdown pe-3">

                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <img :src="applicationStore.profileDetails?.avatarUrl.toString()" alt="Profile"
                             class="rounded-circle">
                        <span class="d-none d-md-block dropdown-toggle ps-2">
                            {{
 applicationStore.profileDetails?.userName
                            }}
                        </span>
                    </a>

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ applicationStore.profileDetails?.userName }}</h6>
                            <span>{{ applicationStore.profileDetails?.jobTitle }}</span>
                        </li>

                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center"
                               :href="applicationStore.profileDetails?.profileUrl.toString()" target="_blank">
                                <BootstrapIconComponent icon="person" :height="16" :width="16" />
                                <span class="ms-2">My Profile</span>
                            </a>
                        </li>

                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <button class="dropdown-item d-flex align-items-center" @click="refreshAccess">
                                <BootstrapIconComponent icon="recycle" :height="16" :width="16" />
                                <span class="ms-2">Refresh Access</span>
                            </button>
                        </li>

                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <button class="dropdown-item d-flex align-items-center" @click="emit('logout')">
                                <BootstrapIconComponent icon="box-arrow-right" :height="16" :width="16" />
                                <span class="ms-2">Sign Out</span>
                            </button>
                        </li>

                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li v-if="reducedInfo">
                            <button class="dropdown-item d-flex align-items-center">
                                <BootstrapIconComponent icon="info-circle" :height="16" :width="16" />
                                <span class="ms-2 small">{{ releaseNumber }}</span>
                            </button>

                        </li>

                        <li v-if="!reducedInfo">
                            <button class="dropdown-item d-flex align-items-center">
                                <BootstrapIconComponent icon="info-circle" :height="16" :width="16" />
                                <span class="ms-2 small">{{ environment }} | B:{{ buildNumber }} | {{ releaseNumber }}</span>
                            </button>

                        </li>
                    </ul>
                </li>

            </ul>
        </nav>

    </header>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

            <li class="nav-item" @click="onOpenPacks">
                <a class="nav-link"><font-awesome-icon icon="fa-solid fa-fw fa-box" fixed-width /><span>Packs</span></a>
            </li>

            <li class="nav-item" @click="onOpenSignOffs">
                <a class="nav-link">
                    <font-awesome-icon icon="fa-solid fa-fw fa-file-signature" fixed-width /><span>
                        Sign
                        Offs
                    </span>
                </a>
            </li>
        </ul>
    </aside>
</template>

<script setup lang="ts">

    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from "vue-router";
    import { useApplicationStore } from "@/stores/applicationStore";
    import { ApiConfig } from '@/config/apiConfig';
    import { UserAccessApi, ApplicationConfigApi } from '@/HttpClient';

    const applicationStore = useApplicationStore();
    const emit = defineEmits(['logout']);
    const router = useRouter();
    const apiUserAccess = new UserAccessApi(new ApiConfig());
    const apiApplicationConfig = new ApplicationConfigApi(new ApiConfig());

    let environment = ref("localhost");
    let buildNumber = ref("20240213.2");
    let releaseNumber = ref("9999");

    const reducedInfo = computed(() => getReducedInfo());

    onMounted(() => {
        getVersionInfo();
    })

    function getVersionInfo() {
        apiApplicationConfig.apiApplicationConfigGet()
            .then((data) => {
                environment.value = data.environment ?? "";
                buildNumber.value = data.buildNumber ?? "";
                releaseNumber.value = data.releaseNumber ?? "";
            });
    }

    function getReducedInfo() {
        let reducedInfo = false;
        switch (environment.value) {
            case "localhost":
            case "DEV":
            case "BAT":
            case "UAT":
                reducedInfo = false;
                break;
            case "PROD":
            default:
                reducedInfo = true;
                break;

        }
        return reducedInfo;
    }

    function onOpenPacks() {
        router.push({ name: 'home' });
        onToggleSidebar();
    }

    function refreshAccess() {
        apiUserAccess.apiUserAccessClearCachedPut();
        router.push({ name: 'accessrefreshedview' });
    }

    function onOpenSignOffs() {
        router.push({ name: 'signoffindex' });
        onToggleSidebar();
    }

    function onToggleSidebar() {
        document.body.classList.toggle('toggle-sidebar');
    }

</script>