/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CorrectiveActionDto } from './CorrectiveActionDto';
import {
    CorrectiveActionDtoFromJSON,
    CorrectiveActionDtoFromJSONTyped,
    CorrectiveActionDtoToJSON,
} from './CorrectiveActionDto';

/**
 * 
 * @export
 * @interface GetCorrectiveActionsViewModel
 */
export interface GetCorrectiveActionsViewModel {
    /**
     * 
     * @type {Array<CorrectiveActionDto>}
     * @memberof GetCorrectiveActionsViewModel
     */
    correctiveActions?: Array<CorrectiveActionDto> | null;
}

/**
 * Check if a given object implements the GetCorrectiveActionsViewModel interface.
 */
export function instanceOfGetCorrectiveActionsViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCorrectiveActionsViewModelFromJSON(json: any): GetCorrectiveActionsViewModel {
    return GetCorrectiveActionsViewModelFromJSONTyped(json, false);
}

export function GetCorrectiveActionsViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorrectiveActionsViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'correctiveActions': !exists(json, 'correctiveActions') ? undefined : (json['correctiveActions'] === null ? null : (json['correctiveActions'] as Array<any>).map(CorrectiveActionDtoFromJSON)),
    };
}

export function GetCorrectiveActionsViewModelToJSON(value?: GetCorrectiveActionsViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'correctiveActions': value.correctiveActions === undefined ? undefined : (value.correctiveActions === null ? null : (value.correctiveActions as Array<any>).map(CorrectiveActionDtoToJSON)),
    };
}

