<template>
    <div class="check-item-grid flo-pack-item" :class="accentStyle">
        <div class="description">
            <div class="d-inline-block me-1 flo-pack-icon" @click="onToggleHelp">
                <font-awesome-icon :icon="icon" size="lg" fixed-width class="check-icon" />
            </div>
            {{ packCheck.name }}
            <font-awesome-icon v-if="isOverdue && !isCompleted" icon="fa-solid fa-circle-exclamation" fixed-width class="text-danger" />
        </div>
        <div class="details">
            <div v-if="!wasMissed" :class="checkTimeClass"><small>{{ completeStatement }}</small></div>
            <div v-if="wasMissed" v-html="missedDescription"></div>

            <div v-show="showHelpText" class="p-2" :class="helpStyle" @click="onToggleHelp">
                <div class="flo-help-title fw-bold">
                    <font-awesome-icon icon="fa-solid fa-circle-info" /> {{ packCheck.sectionName }}
                </div>
                <div class="flo-help-subtitle">
                    {{ packCheck.name }}
                </div>
                <div class="flo-help-text" v-html="packCheck.helpText"></div>

            </div>
        </div>
        <div class="icon check-action-icon">
            <font-awesome-icon v-if="(isCompleted || isExplained) && !wasMissed" icon="fa-solid fa-circle-check" size="2x" class="text-success" :data-testid="packCheck.checkTypeKey"/>
            <font-awesome-icon v-if="!isCompleted && canComplete" icon="fa-solid fa-chevron-right" size="2x" @click.once="onClickCheck" :key="props.resetClick" :data-testid="packCheck.checkTypeKey" />
            <font-awesome-icon v-if="!isCompleted && isOverdue" icon="fa-solid fa-triangle-exclamation" size="2x" @click.once="onClickCheck" :key="props.resetClick" class="text-danger" :data-testid="packCheck.checkTypeKey" />
            <font-awesome-icon v-if="wasMissed" icon="fa-solid fa-triangle-exclamation" size="2x" class="text-danger" :data-testid="packCheck.checkTypeKey"/>
        </div>

    </div>
</template>

<script setup lang="ts">

    import { computed, ref } from 'vue'
    import { formatPackCheckCompletionStatement, formatPackCheckMissedDescription } from "@/utils/packCheckFormat"
    import type { PackCheckDto } from "@/HttpClient"
    import { mapToAccentStyle, mapToHelpStyle } from '@/utils/styleMaps'

    interface Props {
        packCheck: PackCheckDto,
        sectionName: string,
        resetClick : number
    }

    const props = defineProps<Props>()
    const emit = defineEmits<{ (e: 'check-selected', data: PackCheckDto): void }>()
    const completeStatement = computed(() => formatPackCheckCompletionStatement(props.sectionName, props.packCheck.availableFromDateTime, props.packCheck.dueDateTime, props.packCheck.checkedDate, props.packCheck.checkedByName))
    const missedDescription = computed(() => formatPackCheckMissedDescription(props.packCheck.dueDateTime, props.packCheck.missedExplanation, props.packCheck.checkedDate, props.packCheck.checkedByName))
    const icon = computed(() => 'fa-solid fa-fw ' + props.packCheck.icon)
    const canComplete = computed(() => props.sectionName == "Active" && !props.packCheck.isCompleted)
    let showHelpText = ref(false)

    let checkTimeClass = computed(() => {
        return isCompleted.value ? 'text-success font-italic flo-item-time d-inline-block' : isOverdue.value ? 'fw-bold text-danger flo-item-time d-inline-block' : 'flo-item-time font-italic d-inline-block';
    });

    function onClickCheck() {
        emit('check-selected', props.packCheck)
    }

    function onToggleHelp() {
        showHelpText.value = !showHelpText.value;
    }

    const accentStyle = computed(() => {
        return mapToAccentStyle(props.packCheck.colour)
    })

    const helpStyle = computed(() => {
        return mapToHelpStyle(props.packCheck.colour)
    })

    const isOverdue = computed(() => props.packCheck.isMissed && !props.packCheck.missedExplanation)
    const isExplained = computed(() => props.packCheck.isMissed && props.packCheck.missedExplanation)
    const isCompleted = computed(() => props.packCheck.isCompleted)
    const wasMissed = computed(() => props.packCheck.wasMissed)
</script>
<style lang="scss">
    .flo-pack-item {
        & > .description {
            font-size: 1em;
        }
    }
</style>