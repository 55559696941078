<template>

    <HeaderNavigation @logout="logoutCalled" />
    
    <main id="main" class="main hasUpdated">
        <router-view></router-view>
       <!-- Healthcheck -->
        <!-- <HealthCheck /> -->
    </main>

</template>

<script setup lang="ts">

    // Vue Libraries
    import { RouterView } from "vue-router";
    import { onMounted,watch } from 'vue'

    // Components
    // import HealthCheck from "@/components/root/HealthCheckComponent.vue";
    import HeaderNavigation from "@/components/root/HeaderNavigationComponent.vue";

    // Stores & Services
    import AuthService from "@/services/application/authService";

    import { useAuthStore } from "./stores/authStore";

    const authSvc = new AuthService();

    function logoutCalled() {
        const authStore = useAuthStore();
        if(authStore.user.isAuthenticated){
            authStore.manualSignOut();
            authSvc.logout();
        }
    }

</script>

