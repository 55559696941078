/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CorrectiveActionResponseDto } from './CorrectiveActionResponseDto';
import {
    CorrectiveActionResponseDtoFromJSON,
    CorrectiveActionResponseDtoFromJSONTyped,
    CorrectiveActionResponseDtoToJSON,
} from './CorrectiveActionResponseDto';

/**
 * 
 * @export
 * @interface StoreManagerSignOffCommand
 */
export interface StoreManagerSignOffCommand {
    /**
     * 
     * @type {number}
     * @memberof StoreManagerSignOffCommand
     */
    packId?: number;
    /**
     * 
     * @type {Array<CorrectiveActionResponseDto>}
     * @memberof StoreManagerSignOffCommand
     */
    correctiveActions?: Array<CorrectiveActionResponseDto> | null;
}

/**
 * Check if a given object implements the StoreManagerSignOffCommand interface.
 */
export function instanceOfStoreManagerSignOffCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StoreManagerSignOffCommandFromJSON(json: any): StoreManagerSignOffCommand {
    return StoreManagerSignOffCommandFromJSONTyped(json, false);
}

export function StoreManagerSignOffCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreManagerSignOffCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'packId': !exists(json, 'packId') ? undefined : json['packId'],
        'correctiveActions': !exists(json, 'correctiveActions') ? undefined : (json['correctiveActions'] === null ? null : (json['correctiveActions'] as Array<any>).map(CorrectiveActionResponseDtoFromJSON)),
    };
}

export function StoreManagerSignOffCommandToJSON(value?: StoreManagerSignOffCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'packId': value.packId,
        'correctiveActions': value.correctiveActions === undefined ? undefined : (value.correctiveActions === null ? null : (value.correctiveActions as Array<any>).map(CorrectiveActionResponseDtoToJSON)),
    };
}

