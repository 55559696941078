/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissedCheckDto
 */
export interface MissedCheckDto {
    /**
     * 
     * @type {number}
     * @memberof MissedCheckDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    checkName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    checkIcon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    checkColour?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MissedCheckDto
     */
    checkDueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    missedReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    missedUser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissedCheckDto
     */
    correctiveAction?: number;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckDto
     */
    correctiveActionDescription?: string | null;
}

/**
 * Check if a given object implements the MissedCheckDto interface.
 */
export function instanceOfMissedCheckDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MissedCheckDtoFromJSON(json: any): MissedCheckDto {
    return MissedCheckDtoFromJSONTyped(json, false);
}

export function MissedCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissedCheckDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'checkName': !exists(json, 'checkName') ? undefined : json['checkName'],
        'checkIcon': !exists(json, 'checkIcon') ? undefined : json['checkIcon'],
        'checkColour': !exists(json, 'checkColour') ? undefined : json['checkColour'],
        'checkDueDate': !exists(json, 'checkDueDate') ? undefined : (new Date(json['checkDueDate'])),
        'missedReason': !exists(json, 'missedReason') ? undefined : json['missedReason'],
        'missedUser': !exists(json, 'missedUser') ? undefined : json['missedUser'],
        'correctiveAction': !exists(json, 'correctiveAction') ? undefined : json['correctiveAction'],
        'correctiveActionDescription': !exists(json, 'correctiveActionDescription') ? undefined : json['correctiveActionDescription'],
    };
}

export function MissedCheckDtoToJSON(value?: MissedCheckDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'checkName': value.checkName,
        'checkIcon': value.checkIcon,
        'checkColour': value.checkColour,
        'checkDueDate': value.checkDueDate === undefined ? undefined : (value.checkDueDate.toISOString()),
        'missedReason': value.missedReason,
        'missedUser': value.missedUser,
        'correctiveAction': value.correctiveAction,
        'correctiveActionDescription': value.correctiveActionDescription,
    };
}

