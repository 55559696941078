import { ApiMiddleware } from './ApiMiddleware';
import { type HTTPHeaders } from '@/HttpClient/runtime';
import { type ConfigurationParameters, Configuration } from '@/HttpClient';

export class ApiConfig extends Configuration {

constructor(){
    const head : HTTPHeaders = {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type" : "application/json",
        "Accept" : "application/json"
    }
   
    const params : ConfigurationParameters = {
        basePath : `${window.location.protocol}//${window.location.host}`,
        credentials: "include",
        headers: head,
        middleware: [new ApiMiddleware()]
    }

    
    super(params);
}
}