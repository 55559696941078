import { Popover } from 'bootstrap';
import { type DirectiveBinding, type Ref } from 'vue';

export interface PopoverOptions {
  title: string;
  content: string;
  placement: 'auto' | 'top' | 'bottom' | 'left' | 'right';
  trigger: 'manual' | 'click' | 'hover' | 'focus';
  show: Ref<boolean>
}

export const vPopover = {
  mounted(el : any , binding: DirectiveBinding<PopoverOptions>) {
    const popover = new Popover(el, {
      title: binding.value.title,
      content: binding.value.content,
      placement: binding.value.placement,
      trigger: binding.value.trigger
    });

    popover.enable();
  
  },
  unmounted(el : any) {
    const popover = Popover.getInstance(el);
    if (popover) {
      popover.dispose();
    }
  }
  
};
