/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackCheckDto } from './PackCheckDto';
import {
    PackCheckDtoFromJSON,
    PackCheckDtoFromJSONTyped,
    PackCheckDtoToJSON,
} from './PackCheckDto';

/**
 * 
 * @export
 * @interface PackSectionDto
 */
export interface PackSectionDto {
    /**
     * 
     * @type {string}
     * @memberof PackSectionDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackSectionDto
     */
    colour?: string | null;
    /**
     * 
     * @type {Array<PackCheckDto>}
     * @memberof PackSectionDto
     */
    checks?: Array<PackCheckDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PackSectionDto
     */
    readonly checkCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PackSectionDto
     */
    readonly missedCheckCount?: number;
}

/**
 * Check if a given object implements the PackSectionDto interface.
 */
export function instanceOfPackSectionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PackSectionDtoFromJSON(json: any): PackSectionDto {
    return PackSectionDtoFromJSONTyped(json, false);
}

export function PackSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackSectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'colour': !exists(json, 'colour') ? undefined : json['colour'],
        'checks': !exists(json, 'checks') ? undefined : (json['checks'] === null ? null : (json['checks'] as Array<any>).map(PackCheckDtoFromJSON)),
        'checkCount': !exists(json, 'checkCount') ? undefined : json['checkCount'],
        'missedCheckCount': !exists(json, 'missedCheckCount') ? undefined : json['missedCheckCount'],
    };
}

export function PackSectionDtoToJSON(value?: PackSectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'colour': value.colour,
        'checks': value.checks === undefined ? undefined : (value.checks === null ? null : (value.checks as Array<any>).map(PackCheckDtoToJSON)),
    };
}

