/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushNotificationCallbackCommand
 */
export interface PushNotificationCallbackCommand {
    /**
     * 
     * @type {string}
     * @memberof PushNotificationCallbackCommand
     */
    callbackToken?: string | null;
}

/**
 * Check if a given object implements the PushNotificationCallbackCommand interface.
 */
export function instanceOfPushNotificationCallbackCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushNotificationCallbackCommandFromJSON(json: any): PushNotificationCallbackCommand {
    return PushNotificationCallbackCommandFromJSONTyped(json, false);
}

export function PushNotificationCallbackCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushNotificationCallbackCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callbackToken': !exists(json, 'callbackToken') ? undefined : json['callbackToken'],
    };
}

export function PushNotificationCallbackCommandToJSON(value?: PushNotificationCallbackCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'callbackToken': value.callbackToken,
    };
}

