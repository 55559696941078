
// API
import { NotificationApi } from '@/HttpClient';
import { ApiConfig } from '@/config/apiConfig';
import config from '@/config/configuration.json'
import {defined} from '@/utils/nullable'

export default class PushService {
  
  askPermission() {
    console.log("Ask Permission loaded");
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then(function(permissionResult) {
        if (permissionResult !== 'granted') {
          reject(new Error("We weren't granted permission."));
        } else {
          resolve(permissionResult);
        }
      });
    });
  }

  unsubscribe(){
    const api = new NotificationApi(new ApiConfig());
    navigator.serviceWorker.getRegistration().then((reg) => {              
      console.log(reg); 
      reg?.pushManager.getSubscription().then((sub) => {
        api.apiNotificationUnsubscribePost({pushNotificationUnsubscribeCommand:{
          endpoint : sub?.endpoint
        } 
      })
      })
    });
  }
    
    subscribeToPush(reg: ServiceWorkerRegistration): Promise<PushSubscription> {
      console.log("Subscribe to push triggered");
        return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.vapidPublicKey
        });
    }
    
    handlePushSubscription(oldSubscription : PushSubscription | null , pushSubscription : PushSubscription | null) {
        console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
        const api = new NotificationApi(new ApiConfig());
    
        if (!pushSubscription) throw new Error("No Subscription");

        const p256dh_base64encoded = this.base64Encode(pushSubscription, 'p256dh');
        const auth_base64encoded = this.base64Encode(pushSubscription, 'auth');

        api.apiNotificationSubscribePost({
            pushNotificationSubscribeCommand: {
                oldEndpoint : oldSubscription?.endpoint,
                endpoint: pushSubscription.endpoint,
                p256dh: p256dh_base64encoded,
                auth: auth_base64encoded
              }
        });
    }
    
    base64Encode(pushSubscription : PushSubscription, key : PushEncryptionKeyName) {
        try {
            const value = pushSubscription.getKey(key);
            if (value) {
                return btoa(String.fromCharCode.apply(null, [...new Uint8Array(value)]));
            }
        } catch (e) {
            // Handle the exception if needed
        }
        return '';
    }
    
    subscribeUserToPush() {
        navigator.serviceWorker.getRegistration().then((reg) => {              
          console.log(reg); 
          reg?.pushManager.getSubscription().then((sub) => {
                  console.log(sub);
                    const oldSub = sub;
                    if(oldSub){
                        //send it to the server in case it needs updating with new store number (devices may move between stores)
                        this.handlePushSubscription(oldSub,sub);
                    }else{
                    this.subscribeToPush(reg).then((sub) => this.handlePushSubscription(null,sub)).catch((e) => {
                        console.error("Unable to subscribe to push", e);
                    });
                  }
            });
        });
    }
    
}
