/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignOffPackDto } from './SignOffPackDto';
import {
    SignOffPackDtoFromJSON,
    SignOffPackDtoFromJSONTyped,
    SignOffPackDtoToJSON,
} from './SignOffPackDto';

/**
 * 
 * @export
 * @interface SignOffPackSectionDto
 */
export interface SignOffPackSectionDto {
    /**
     * 
     * @type {string}
     * @memberof SignOffPackSectionDto
     */
    sectionName?: string | null;
    /**
     * 
     * @type {Array<SignOffPackDto>}
     * @memberof SignOffPackSectionDto
     */
    packs?: Array<SignOffPackDto> | null;
}

/**
 * Check if a given object implements the SignOffPackSectionDto interface.
 */
export function instanceOfSignOffPackSectionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SignOffPackSectionDtoFromJSON(json: any): SignOffPackSectionDto {
    return SignOffPackSectionDtoFromJSONTyped(json, false);
}

export function SignOffPackSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignOffPackSectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionName': !exists(json, 'sectionName') ? undefined : json['sectionName'],
        'packs': !exists(json, 'packs') ? undefined : (json['packs'] === null ? null : (json['packs'] as Array<any>).map(SignOffPackDtoFromJSON)),
    };
}

export function SignOffPackSectionDtoToJSON(value?: SignOffPackSectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sectionName': value.sectionName,
        'packs': value.packs === undefined ? undefined : (value.packs === null ? null : (value.packs as Array<any>).map(SignOffPackDtoToJSON)),
    };
}

