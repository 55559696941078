<script setup lang="ts">

    import { useRouter } from "vue-router";
    import { useAuthStore } from "@/stores/authStore";

    const router = useRouter();
    const authStore = useAuthStore();

    function redirectToLandingPage() {
        authStore.manualSignOut();
        router.push({ name: "landingPage" });
    }

</script>

<template>
    <div class="container py-4">
        <div class="p-5 mb-4 bg-light rounded-3 text-center">
            <h1 class="display-5 fw-bold">
                We couldn't log you in.
            </h1>
            <p class="col-md fs-4">
                Sorry, there was a problem and we couldn't log you in to CheckFlow.
            </p>
            <button class="btn btn-primary btn-lg" @click="redirectToLandingPage()">
                Click here to try again
            </button>
        </div>
    </div>
</template>
