/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignOffPackDto
 */
export interface SignOffPackDto {
    /**
     * 
     * @type {number}
     * @memberof SignOffPackDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    packName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    packDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SignOffPackDto
     */
    retailWeek?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SignOffPackDto
     */
    allowSignOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignOffPackDto
     */
    missed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignOffPackDto
     */
    isFullySignedOff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    storeSignOffName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SignOffPackDto
     */
    storeSignOffTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SignOffPackDto
     */
    areaManagerSignOffName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SignOffPackDto
     */
    areaManagerSignOffTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SignOffPackDto
     */
    skillRequired?: number | null;
}

/**
 * Check if a given object implements the SignOffPackDto interface.
 */
export function instanceOfSignOffPackDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SignOffPackDtoFromJSON(json: any): SignOffPackDto {
    return SignOffPackDtoFromJSONTyped(json, false);
}

export function SignOffPackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignOffPackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'packName': !exists(json, 'packName') ? undefined : json['packName'],
        'packDescription': !exists(json, 'packDescription') ? undefined : json['packDescription'],
        'retailWeek': !exists(json, 'retailWeek') ? undefined : json['retailWeek'],
        'allowSignOff': !exists(json, 'allowSignOff') ? undefined : json['allowSignOff'],
        'missed': !exists(json, 'missed') ? undefined : json['missed'],
        'isFullySignedOff': !exists(json, 'isFullySignedOff') ? undefined : json['isFullySignedOff'],
        'storeSignOffName': !exists(json, 'storeSignOffName') ? undefined : json['storeSignOffName'],
        'storeSignOffTime': !exists(json, 'storeSignOffTime') ? undefined : (json['storeSignOffTime'] === null ? null : new Date(json['storeSignOffTime'])),
        'areaManagerSignOffName': !exists(json, 'areaManagerSignOffName') ? undefined : json['areaManagerSignOffName'],
        'areaManagerSignOffTime': !exists(json, 'areaManagerSignOffTime') ? undefined : (json['areaManagerSignOffTime'] === null ? null : new Date(json['areaManagerSignOffTime'])),
        'skillRequired': !exists(json, 'skillRequired') ? undefined : json['skillRequired'],
    };
}

export function SignOffPackDtoToJSON(value?: SignOffPackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'packName': value.packName,
        'packDescription': value.packDescription,
        'retailWeek': value.retailWeek,
        'allowSignOff': value.allowSignOff,
        'missed': value.missed,
        'isFullySignedOff': value.isFullySignedOff,
        'storeSignOffName': value.storeSignOffName,
        'storeSignOffTime': value.storeSignOffTime === undefined ? undefined : (value.storeSignOffTime === null ? null : value.storeSignOffTime.toISOString()),
        'areaManagerSignOffName': value.areaManagerSignOffName,
        'areaManagerSignOffTime': value.areaManagerSignOffTime === undefined ? undefined : (value.areaManagerSignOffTime === null ? null : value.areaManagerSignOffTime.toISOString()),
        'skillRequired': value.skillRequired,
    };
}

