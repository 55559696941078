/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetCurrentStoreCommand
 */
export interface SetCurrentStoreCommand {
    /**
     * 
     * @type {number}
     * @memberof SetCurrentStoreCommand
     */
    storeId?: number;
}

/**
 * Check if a given object implements the SetCurrentStoreCommand interface.
 */
export function instanceOfSetCurrentStoreCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SetCurrentStoreCommandFromJSON(json: any): SetCurrentStoreCommand {
    return SetCurrentStoreCommandFromJSONTyped(json, false);
}

export function SetCurrentStoreCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetCurrentStoreCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeId': !exists(json, 'storeId') ? undefined : json['storeId'],
    };
}

export function SetCurrentStoreCommandToJSON(value?: SetCurrentStoreCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storeId': value.storeId,
    };
}

