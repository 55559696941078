<template>
    <div class="card p-2 mt-2 mb-0 bg-danger bg-opacity-25" v-if="showError">
        <div class="card-body p-0">        
            <h4 class="text-danger"><font-awesome-icon icon="fa-exclamation-circle" class="me-2" />{{ props.serverError.title }}</h4>
            {{ props.serverError.detail }}
        </div>
    </div>
</template>

<script setup lang="ts">

    import { computed } from 'vue';
    import type ProblemDetails from '@/config/ProblemDetails';

    interface Props { serverError: ProblemDetails }
    const props = defineProps<Props>()

    let showError = computed(() => {
        return props.serverError;
    })
    
</script>