<template>

    <div class="accordion-item">
        <h2 class="accordion-header" :id="`header-${targetName}`">
            <button :class="collapsedClass" type="button" data-bs-toggle="collapse" :data-bs-target="`#section-target-${targetName}`" :aria-expanded="props.isShown" :aria-controls="`section-target-${targetName}`">
                <span>
                    {{ packSection.name }}
                </span>
                <span class="badge rounded-pill text-bg-info ms-1 me-1">
                    {{ filteredChecks.length }}
                </span>
                <span v-if="packSection.missedCheckCount??0 > 0">
                    including
                </span>
                <span v-if="packSection.missedCheckCount??0 > 0" class="badge rounded-pill text-bg-danger ms-1 me-1">
                    {{packSection.missedCheckCount}}
                </span>
                <span v-if="packSection.missedCheckCount??0 > 0">
                    missed checks
                </span>
            </button>
        </h2>
        <div :id="`section-target-${targetName}`" :class="showSection" data-bs-parent="#packAccordion">
            <div class="accordion-body p-0">
                <div class="pack-check-list">
                    <PackCheck v-for="check in sortedFilteredChecks" :key='check.responsePackCheckId' :reset-click="props.resetClick" :packCheck="check" :sectionName="asString(packSection.name)" @check-selected="onPackSelected" />
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">

    import PackCheck from '@/components/PackCheck.vue'
    import type { PackCheckDto, PackSectionDto } from "@/HttpClient"
    import { sortDates } from '@/utils/dateFilters';
    import { asString } from '@/utils/nullable'

    import { computed, ref } from 'vue'

    interface Props {
        packSection: PackSectionDto,
        isShown: boolean,
        resetClick : number
    }
    const props = defineProps<Props>()
    const emit = defineEmits<{ (e: 'check-selected', check: PackCheckDto): void }>()

    let targetName = ref<string>(props.packSection.name?.replaceAll(' ', '-') ?? "")

    const filteredChecks = computed(() => {
        return props.packSection.checks ?? []
    })

    const sortedFilteredChecks = computed(() => {
        let x = [...filteredChecks.value]
        return x.sort( (a: PackCheckDto, b: PackCheckDto) => {
                return sortDates(a.dueDateTime, b.dueDateTime)
        })
    })

    const showSection = computed(() => {
        return props.isShown ? 'accordion-collapse collapse show' : 'accordion-collapse collapse';
    });

    const collapsedClass = computed(() => {
        return props.isShown ? 'accordion-button fw-bold' : 'accordion-button fw-bold collapsed';
    });

    function onPackSelected(data: PackCheckDto) {
        emit('check-selected', data);
    }

</script>