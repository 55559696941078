<template>
    <div>
        <input class="form-control"  v-if="isText" v-model="selection" />
        <input class="form-control" v-else-if="isDate"  type="datetime-local" :value="selection" @input="onUpdateDate($event)" />
        <input class="form-control" v-else-if="isNumber" type="number" :value="selection" @input="onUpdateNumber" />
        <select class="form-select" v-else-if="isYesOrNo" v-model="selection">
            <option value="Yes">Yes</option>
            <option value ="No">No</option>
        </select>
    </div>
</template>


<script setup lang="ts">
    import { ref, computed, watch, onMounted, onUpdated } from 'vue';
    import {SupplementaryValueTypes} from '@/enums/SupplementalValueTypes'

    interface Props{
        type: string    
        initialValue : string
    }
    const props = defineProps<Props>();

    const emit = defineEmits<{ 
        (e: 'selection-changed', data: string): void 
    }>()

    let isDate = computed(() => props.type === SupplementaryValueTypes.DATE)
    let isText = computed(() => props.type === SupplementaryValueTypes.TEXT)
    let isNumber = computed(() => props.type === SupplementaryValueTypes.NUMBER)
    let isYesOrNo = computed(() => props.type === SupplementaryValueTypes.YES_NO)

    const selection = ref("")

    watch(selection, () => {
        emit('selection-changed', selection.value)
    })

    watch(props, () => {
        if(props.type === 'YesOrNo'){
            selection.value="No"
        }
    })

    onMounted(() =>{
        if(props.type === 'YesOrNo'){
            selection.value="No"
        }
    })

    function onUpdateDate(val : Event){
        let ele = val.target as HTMLInputElement
        selection.value = String(ele.value)
    }

    function onUpdateNumber(val : Event){
        let ele = val.target as HTMLInputElement
        selection.value = String(ele.value)
    }

</script>