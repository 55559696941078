
import { format } from 'date-fns'

export function formatTime (dt : Date | null | undefined) : string{
    if(!dt) return ""
    return format(dt, "HH:mm");
}

export function formatDate(dt: Date | null |undefined) : string{
    if(!dt) return ""
    return format(dt, "dd/MM/yy");
}

export function formatDateTime(dt: Date | null | undefined): string {
    if (!dt) return ""
    return format(dt, "dd/MM HH:mm");
}

