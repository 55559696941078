import AuthService from '@/services/application/authService';
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/base/HomeView.vue';
import LandingPageView from '../views/base/LandingPageView.vue';
import PackView from '@/views/base/PackView.vue';
import SignOffIndexView from '@/views/base/SignOffIndexView.vue';
import SignOffView from '@/views/base/SignOffView.vue';
import SignedOutView from '@/views/base/SignedOutView.vue'
import RemoteErrorView from '@/views/error/RemoteErrorView.vue'
import AccessDeniedView from '@/views/error/AccessDeniedView.vue'
import AccessRefreshedView from '@/views/base/AccessRefreshedView.vue';
import config from '../config/configuration.json';

// CONSTS
import { Policy } from "@/enums/Policy";

// FUNCS
import { differenceInMinutes } from "date-fns";
import { useAuthStore } from "@/stores/authStore";
import { routeToInt } from './helper'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "landingPage",
            component: LandingPageView,
            meta: {
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path: "/home",
            name: "home",
            component: HomeView,
            meta: {
                title: "Home",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path: "/pack/:id",
            name: "packView",
            component: PackView,
            props: routeToInt,
            meta: {
                title: "Pack",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path: "/signoff",
            name: "signoffindex",
            component: SignOffIndexView,
            meta: {
                title: "Sign-Off",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path: "/signoff/:id",
            name: "signoffview",
            component: SignOffView,
            props: routeToInt,
            meta: {
                title: "Sign-Off",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path: "/accessrefreshed",
            name: "accessrefreshedview",
            component: AccessRefreshedView,
            meta: {
                title: "Access Refreshed",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS]
            }
        },
        {
            path:"/signedout",
            name: "signedOutView",
            component: SignedOutView,
            meta:{
                title: "Signed Out",
                requiresAuth: false
            }
        },
        {
            path: "/signin-error",
            name: "signinError",
            component: RemoteErrorView,
            meta: {
                title: "Login Failed",
                requiresAuth: false
            }
        },
        {
            path: "/accessdenied",
            name: "accessDenied",
            component: AccessDeniedView,
            meta: {
                title: "Access Denied",
                requiresAuth: false
            }
        }
    ]
})

router.beforeEach(async (targetRoute) => {
    document.title = `CheckFlow | ${targetRoute.meta.title ? targetRoute.meta.title : ""}`;
    const authStore = useAuthStore();
    const authSvc = new AuthService();

    const requiresAuth = targetRoute.matched.some(
        (route) => route.meta.requiresAuth
    );

    function needsAuthCheck() {
        const authCheck =
            differenceInMinutes(new Date(), authStore.lastAuthenticated) >
            config.AUTH_CHECK_THRESHOLD;

        return authCheck;
    }

    function authenticated() {
        return authStore.user?.isAuthenticated ?? false;
    }

    if (requiresAuth ?? true) {

        if (!window.location.href.includes("/signedOut") && authenticated() && needsAuthCheck()){
            authStore.autoSignOut()
            window.location.href = '/signedOut'
        }
        if (!authenticated() ){
            await authSvc.getUser();

            if (!authenticated()) {
                authSvc.login(targetRoute.fullPath);
                return false;
            }
        }

        if (
            !authStore.user.meetsPolicies(
                (targetRoute.meta.requiredPolicy ?? []) as Policy[]
            ) || authStore.user.permissions?.length == 0
        ) {
            {
                router.push("accessDenied");
            }
        }
    }
});

export default router
