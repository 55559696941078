/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddMissedReasonCommand,
  CompleteCheckCommand,
} from '../models/index';
import {
    AddMissedReasonCommandFromJSON,
    AddMissedReasonCommandToJSON,
    CompleteCheckCommandFromJSON,
    CompleteCheckCommandToJSON,
} from '../models/index';

export interface ApiCheckResponseCompleteCheckPostRequest {
    completeCheckCommand?: CompleteCheckCommand;
}

export interface ApiCheckResponseExplainMissedPostRequest {
    addMissedReasonCommand?: AddMissedReasonCommand;
}

/**
 * 
 */
export class CheckResponseApi extends runtime.BaseAPI {

    /**
     */
    async apiCheckResponseCompleteCheckPostRaw(requestParameters: ApiCheckResponseCompleteCheckPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/CheckResponse/completeCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteCheckCommandToJSON(requestParameters.completeCheckCommand),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiCheckResponseCompleteCheckPost(requestParameters: ApiCheckResponseCompleteCheckPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiCheckResponseCompleteCheckPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCheckResponseExplainMissedPostRaw(requestParameters: ApiCheckResponseExplainMissedPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/CheckResponse/explainMissed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMissedReasonCommandToJSON(requestParameters.addMissedReasonCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiCheckResponseExplainMissedPost(requestParameters: ApiCheckResponseExplainMissedPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.apiCheckResponseExplainMissedPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
