/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementaryQuestionResponseDto } from './SupplementaryQuestionResponseDto';
import {
    SupplementaryQuestionResponseDtoFromJSON,
    SupplementaryQuestionResponseDtoFromJSONTyped,
    SupplementaryQuestionResponseDtoToJSON,
} from './SupplementaryQuestionResponseDto';

/**
 * 
 * @export
 * @interface CompleteCheckCommand
 */
export interface CompleteCheckCommand {
    /**
     * 
     * @type {number}
     * @memberof CompleteCheckCommand
     */
    responseCheckId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteCheckCommand
     */
    isNotApplicable?: boolean;
    /**
     * 
     * @type {Array<SupplementaryQuestionResponseDto>}
     * @memberof CompleteCheckCommand
     */
    supplementaryQuestionResponses?: Array<SupplementaryQuestionResponseDto> | null;
}

/**
 * Check if a given object implements the CompleteCheckCommand interface.
 */
export function instanceOfCompleteCheckCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompleteCheckCommandFromJSON(json: any): CompleteCheckCommand {
    return CompleteCheckCommandFromJSONTyped(json, false);
}

export function CompleteCheckCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteCheckCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseCheckId': !exists(json, 'responseCheckId') ? undefined : json['responseCheckId'],
        'isNotApplicable': !exists(json, 'isNotApplicable') ? undefined : json['isNotApplicable'],
        'supplementaryQuestionResponses': !exists(json, 'supplementaryQuestionResponses') ? undefined : (json['supplementaryQuestionResponses'] === null ? null : (json['supplementaryQuestionResponses'] as Array<any>).map(SupplementaryQuestionResponseDtoFromJSON)),
    };
}

export function CompleteCheckCommandToJSON(value?: CompleteCheckCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseCheckId': value.responseCheckId,
        'isNotApplicable': value.isNotApplicable,
        'supplementaryQuestionResponses': value.supplementaryQuestionResponses === undefined ? undefined : (value.supplementaryQuestionResponses === null ? null : (value.supplementaryQuestionResponses as Array<any>).map(SupplementaryQuestionResponseDtoToJSON)),
    };
}

