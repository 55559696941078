import { createApp } from "vue";
import { createPinia } from "pinia";

// import the fontawesome icon component
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import the bootstrap icon compnent
import BootstrapIconComponent from "@/components/root/BootstrapIconComponent.vue";

// Import the icon packs
import {
    faCircleQuestion,
    //faStore,    
    //faShop,
    //faClipboardCheck,
    //faSlidersSimple,
    faBellSlash,
    faCalendar,
} from "@fortawesome/free-regular-svg-icons";

import {
    faCircleExclamation,
    faCircle,
    faCircleInfo,
    faCircleCheck,
    faChevronRight,
    faTriangleExclamation,
    faCheck,
    faCog,
    faRecycle,
    faBolt,
    faBriefcaseMedical,
    faBroom,
    faCreditCard,
    faCubesStacked,
    faDoorOpen,
    faElevator,
    faStairs,
    faFaceSmile,
    faLightbulb,
    faMitten,
    faBug,
    faDolly,
    faPersonFalling,
    faPowerOff,
    faFire,
    faBell,
    faHandSparkles,
    faSnowflake,
    faSoap,
    faSquareParking,
    faTachographDigital,
    faTag,
    faTemperatureHigh,
    faTemperatureQuarter,
    faShop,
    faClipboardCheck,
    faBox,
    faPersonCircleCheck,
    faUser,
    faFileSignature

} from "@fortawesome/free-solid-svg-icons";

// Add icon packs to the library
library.add(faCircleQuestion,
    faBellSlash,
    faCalendar,
    faBellSlash,   
    faCircleExclamation,
    faCircle,
    faCircleInfo,
    faCircleCheck,
    faChevronRight,
    faTriangleExclamation,
    faCheck,
    faCog,
    faRecycle,
    faBolt,
    faBriefcaseMedical,
    faBroom,
    faCreditCard,
    faCubesStacked,
    faDoorOpen,
    faElevator,
    faStairs,
    faFaceSmile,
    faLightbulb,
    faMitten,
    faBug,
    faDolly,
    faPersonFalling,
    faPowerOff,
    faFire,
    faBell,
    faHandSparkles,
    faSnowflake,
    faSoap,
    faSquareParking,
    faTachographDigital,
    faTag,
    faTemperatureHigh,
    faTemperatureQuarter,
    faShop,
    faClipboardCheck,
    faBox,
    faPersonCircleCheck,
    faUser,
    faFileSignature);

import ServerErrorDisplay from "@/components/common/ServerErrorDisplay.vue";

// Services
import ApplicationService from "@/services/application/applicationService";
import "bootstrap";
import "@/assets/scss/site.scss";
import "@/assets/style.css";
import "@/assets/bootstrap-icons.svg";
import App from "./App.vue";
import router from "./router";

// Build the app
const app = createApp(App);

app.use(createPinia());
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("BootstrapIconComponent", BootstrapIconComponent);
app.component("ServerErrorDisplay", ServerErrorDisplay);

app.mount("#app");
const appService = new ApplicationService();
appService.initialiseApplication();
