/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushNotificationSubscribeCommand
 */
export interface PushNotificationSubscribeCommand {
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscribeCommand
     */
    endpoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscribeCommand
     */
    p256dh?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscribeCommand
     */
    auth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationSubscribeCommand
     */
    oldEndpoint?: string | null;
}

/**
 * Check if a given object implements the PushNotificationSubscribeCommand interface.
 */
export function instanceOfPushNotificationSubscribeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushNotificationSubscribeCommandFromJSON(json: any): PushNotificationSubscribeCommand {
    return PushNotificationSubscribeCommandFromJSONTyped(json, false);
}

export function PushNotificationSubscribeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushNotificationSubscribeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'p256dh': !exists(json, 'p256dh') ? undefined : json['p256dh'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
        'oldEndpoint': !exists(json, 'oldEndpoint') ? undefined : json['oldEndpoint'],
    };
}

export function PushNotificationSubscribeCommandToJSON(value?: PushNotificationSubscribeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
        'p256dh': value.p256dh,
        'auth': value.auth,
        'oldEndpoint': value.oldEndpoint,
    };
}

