<script setup lang="ts">
import AuthService from "@/services/application/authService";
import { useAuthStore } from "@/stores/authStore";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const authStore = useAuthStore();
const authService = new AuthService();

if (!authStore.user.isAuthenticated) {
  authService.getUser();
}

onMounted(() => {
  router.push({ name: "home" });
});
</script>

<template>
  <div v-if="!authStore.user.isAuthenticated" class="container py-4">
    <div class="p-5 mb-4 bg-light rounded-3 text-center">
      <h1 class="display-5 fw-bold">
        Signing In
      </h1>
      <p class="col-md fs-4">
        Redirecting to sign-in page.
      </p>
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
          style="width: 100%"
        >
            &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>