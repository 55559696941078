import userApi from "@/api/application/userApi";
import applicationApi from "@/api/application/profileApi";
import healthCheckApi from "@/api/application/healthCheckApi";

const apis = {
  user: new userApi(),
  profile: new applicationApi(),
  healthCheck: new healthCheckApi(),
};

export const apiFactory = {
  useUserApi: () => apis.user,
  useHealthCheckApi: () => apis.healthCheck,
  useProfileApi: () => apis.profile
};
