/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplementaryQuestionDto } from './SupplementaryQuestionDto';
import {
    SupplementaryQuestionDtoFromJSON,
    SupplementaryQuestionDtoFromJSONTyped,
    SupplementaryQuestionDtoToJSON,
} from './SupplementaryQuestionDto';

/**
 * 
 * @export
 * @interface PackCheckDto
 */
export interface PackCheckDto {
    /**
     * 
     * @type {number}
     * @memberof PackCheckDto
     */
    responsePackCheckId?: number;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    helpText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    sectionName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PackCheckDto
     */
    availableFromDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PackCheckDto
     */
    dueDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    colour?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PackCheckDto
     */
    checkTemplateId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PackCheckDto
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackCheckDto
     */
    isMissed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PackCheckDto
     */
    wasMissed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    missedExplanation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PackCheckDto
     */
    checkedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    checkedByName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackCheckDto
     */
    allowNotApplicable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackCheckDto
     */
    checkTypeKey?: string | null;
    /**
     * 
     * @type {Array<SupplementaryQuestionDto>}
     * @memberof PackCheckDto
     */
    supplementaryQuestions?: Array<SupplementaryQuestionDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackCheckDto
     */
    lastThreeAnswers?: Array<string> | null;
}

/**
 * Check if a given object implements the PackCheckDto interface.
 */
export function instanceOfPackCheckDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PackCheckDtoFromJSON(json: any): PackCheckDto {
    return PackCheckDtoFromJSONTyped(json, false);
}

export function PackCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackCheckDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responsePackCheckId': !exists(json, 'responsePackCheckId') ? undefined : json['responsePackCheckId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'helpText': !exists(json, 'helpText') ? undefined : json['helpText'],
        'sectionName': !exists(json, 'sectionName') ? undefined : json['sectionName'],
        'availableFromDateTime': !exists(json, 'availableFromDateTime') ? undefined : (new Date(json['availableFromDateTime'])),
        'dueDateTime': !exists(json, 'dueDateTime') ? undefined : (new Date(json['dueDateTime'])),
        'colour': !exists(json, 'colour') ? undefined : json['colour'],
        'checkTemplateId': !exists(json, 'checkTemplateId') ? undefined : json['checkTemplateId'],
        'isCompleted': !exists(json, 'isCompleted') ? undefined : json['isCompleted'],
        'isMissed': !exists(json, 'isMissed') ? undefined : json['isMissed'],
        'wasMissed': !exists(json, 'wasMissed') ? undefined : json['wasMissed'],
        'missedExplanation': !exists(json, 'missedExplanation') ? undefined : json['missedExplanation'],
        'checkedDate': !exists(json, 'checkedDate') ? undefined : (json['checkedDate'] === null ? null : new Date(json['checkedDate'])),
        'checkedByName': !exists(json, 'checkedByName') ? undefined : json['checkedByName'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
        'checkTypeKey': !exists(json, 'checkTypeKey') ? undefined : json['checkTypeKey'],
        'supplementaryQuestions': !exists(json, 'supplementaryQuestions') ? undefined : (json['supplementaryQuestions'] === null ? null : (json['supplementaryQuestions'] as Array<any>).map(SupplementaryQuestionDtoFromJSON)),
        'lastThreeAnswers': !exists(json, 'lastThreeAnswers') ? undefined : json['lastThreeAnswers'],
    };
}

export function PackCheckDtoToJSON(value?: PackCheckDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responsePackCheckId': value.responsePackCheckId,
        'name': value.name,
        'icon': value.icon,
        'helpText': value.helpText,
        'sectionName': value.sectionName,
        'availableFromDateTime': value.availableFromDateTime === undefined ? undefined : (value.availableFromDateTime.toISOString()),
        'dueDateTime': value.dueDateTime === undefined ? undefined : (value.dueDateTime.toISOString()),
        'colour': value.colour,
        'checkTemplateId': value.checkTemplateId,
        'isCompleted': value.isCompleted,
        'isMissed': value.isMissed,
        'wasMissed': value.wasMissed,
        'missedExplanation': value.missedExplanation,
        'checkedDate': value.checkedDate === undefined ? undefined : (value.checkedDate === null ? null : value.checkedDate.toISOString()),
        'checkedByName': value.checkedByName,
        'allowNotApplicable': value.allowNotApplicable,
        'checkTypeKey': value.checkTypeKey,
        'supplementaryQuestions': value.supplementaryQuestions === undefined ? undefined : (value.supplementaryQuestions === null ? null : (value.supplementaryQuestions as Array<any>).map(SupplementaryQuestionDtoToJSON)),
        'lastThreeAnswers': value.lastThreeAnswers,
    };
}

