/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationConfigViewModel,
} from '../models/index';
import {
    ApplicationConfigViewModelFromJSON,
    ApplicationConfigViewModelToJSON,
} from '../models/index';

/**
 * 
 */
export class ApplicationConfigApi extends runtime.BaseAPI {

    /**
     */
    async apiApplicationConfigGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationConfigViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ApplicationConfig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationConfigViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationConfigGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationConfigViewModel> {
        const response = await this.apiApplicationConfigGetRaw(initOverrides);
        return await response.value();
    }

}
