import { startOfToday, endOfToday } from "date-fns";

// is a date time within or after the current hour of today
export function isCurrent(dateTime: Date){
    return isToday(dateTime) && dateTime.getHours() >= new Date().getHours();
}

// is a date for today
export function isToday(date : Date){
    return date >= startOfToday() && date <= endOfToday();
}

export function dateWithin(start : Date | undefined, end : Date | undefined){
    if (!start && !end) {
        return false;
    }

    let now = new Date();
    
    if(!start ) 
        {return (end as Date) >= now;
    } 
    if(!end)  {
        return (start as Date) < now;
    }
    return start <= now && end >= now
}

export function sortDates(d1 : Date | undefined, d2: Date | undefined){
    if(!d1 || !d2) {
        return -1
    }
    return Number(d1) - Number(d2)

}