export function mapToIconStyle(colour : string | null | undefined){
    if(colour){
        return `flo-check-icon-${colour.toLowerCase()}`
    }
    return 'flo-check-icon-blue'
}

export function mapToAccentStyle(colour : string | null | undefined){
    if(colour){
        return `flo-accent-${colour.toLowerCase()}`
    }
    return 'flo-accent-blue'

}

export function mapToHelpStyle(colour : string | null | undefined){
    if(colour){
        return `flo-help-${colour.toLowerCase()}`
    }
    return 'flo-help-blue'

}