/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CachedUserAccessDto,
} from '../models/index';
import {
    CachedUserAccessDtoFromJSON,
    CachedUserAccessDtoToJSON,
} from '../models/index';

/**
 * 
 */
export class UserAccessApi extends runtime.BaseAPI {

    /**
     */
    async apiUserAccessClearCachedPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserAccess/clearCached`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiUserAccessClearCachedPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiUserAccessClearCachedPutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserAccessGetCachedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CachedUserAccessDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/UserAccess/getCached`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CachedUserAccessDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUserAccessGetCachedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CachedUserAccessDto> {
        const response = await this.apiUserAccessGetCachedGetRaw(initOverrides);
        return await response.value();
    }

}
