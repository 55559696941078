/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackDto
 */
export interface PackDto {
    /**
     * 
     * @type {number}
     * @memberof PackDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PackDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackDto
     */
    checksComplete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PackDto
     */
    checksMissingCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PackDto
     */
    checksActiveCount?: number;
}

/**
 * Check if a given object implements the PackDto interface.
 */
export function instanceOfPackDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PackDtoFromJSON(json: any): PackDto {
    return PackDtoFromJSONTyped(json, false);
}

export function PackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'checksComplete': !exists(json, 'checksComplete') ? undefined : json['checksComplete'],
        'checksMissingCount': !exists(json, 'checksMissingCount') ? undefined : json['checksMissingCount'],
        'checksActiveCount': !exists(json, 'checksActiveCount') ? undefined : json['checksActiveCount'],
    };
}

export function PackDtoToJSON(value?: PackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'checksComplete': value.checksComplete,
        'checksMissingCount': value.checksMissingCount,
        'checksActiveCount': value.checksActiveCount,
    };
}

