/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignOffPackSectionDto } from './SignOffPackSectionDto';
import {
    SignOffPackSectionDtoFromJSON,
    SignOffPackSectionDtoFromJSONTyped,
    SignOffPackSectionDtoToJSON,
} from './SignOffPackSectionDto';

/**
 * 
 * @export
 * @interface GetSignOffPacksViewModel
 */
export interface GetSignOffPacksViewModel {
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPacksViewModel
     */
    storeId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSignOffPacksViewModel
     */
    storeName?: string | null;
    /**
     * 
     * @type {Array<SignOffPackSectionDto>}
     * @memberof GetSignOffPacksViewModel
     */
    packSections?: Array<SignOffPackSectionDto> | null;
}

/**
 * Check if a given object implements the GetSignOffPacksViewModel interface.
 */
export function instanceOfGetSignOffPacksViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetSignOffPacksViewModelFromJSON(json: any): GetSignOffPacksViewModel {
    return GetSignOffPacksViewModelFromJSONTyped(json, false);
}

export function GetSignOffPacksViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSignOffPacksViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeId': !exists(json, 'storeId') ? undefined : json['storeId'],
        'storeName': !exists(json, 'storeName') ? undefined : json['storeName'],
        'packSections': !exists(json, 'packSections') ? undefined : (json['packSections'] === null ? null : (json['packSections'] as Array<any>).map(SignOffPackSectionDtoFromJSON)),
    };
}

export function GetSignOffPacksViewModelToJSON(value?: GetSignOffPacksViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storeId': value.storeId,
        'storeName': value.storeName,
        'packSections': value.packSections === undefined ? undefined : (value.packSections === null ? null : (value.packSections as Array<any>).map(SignOffPackSectionDtoToJSON)),
    };
}

