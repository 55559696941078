<template>

<Teleport to="#teleport-landing">
<a class="nav-link nav-icon text-danger" v-popover="popoverOptions" href="javascript:;" v-if="showNotificationDisabled" @click="reshowNotificationPrompt">
    <font-awesome-icon icon="fa-regular fa-bell-slash" />
</a>
</Teleport>
<ModalPopup :icon="modalIcon" :icon-color="'text-info'" :modelValue="showPermissionModal" :title="'Allow Push Notifications'">
    <template v-slot:default>
        <div v-if="!permissionsBlocked">
            <p>To enable Checkflow to alert you to upcoming tasks, you should enable push notifications</p>

            <p>To continue, please click proceed below, and when prompted by your browser, please click <strong>"Allow"</strong></p>
            <p class="alert alert-info">If you do not see a prompt to Allow notifications, your browser may be blocking the request, please review your site settings</p>
        </div>
        <div v-if="permissionsBlocked" class="alert alert-danger" role="alert">
            <div class="row">
                <div class="col-1">
                    <font-awesome-icon class="mt-2 me-2" icon="fa-solid fa-triangle-exclamation" size="2xl"></font-awesome-icon>
                </div>
                <div class="col">
                    <strong>You or another user has blocked Push notifications on this Device.</strong>
                </div>
            </div>
            <div class="row">
                <div class="col mt-2">
                <p> To re-enable them follow these steps: </p>
                    <p>
                        <ol>
                            <li>Click the <font-awesome-icon icon="fa-regular fa-sliders-simple" size="xl"></font-awesome-icon> icon in the address bar</li>
                            <li>Click Permissions</li>
                            <li>Click <strong>Reset Permissions</strong></li>
                            <li>You will then be prompted to refresh the page, you will then be prompted to allow notifications</li>
                        </ol>
                    </p>
                </div>
            </div>
        </div>

    </template>
    <template v-slot:footer>
        <button class="btn btn-warning" @click="reject">Cancel</button>
        <button class="btn btn-success" v-if="!permissionsBlocked" @click="accept">Proceed</button>
    </template>
</ModalPopup> 

</template>

<script setup lang="ts">
import {ref,computed,watch} from 'vue';
import { usePermission } from '@vueuse/core'
import ModalPopup from './common/ModalPopup.vue';
import PushService from "@/services/application/pushService";
import { debounce } from '@/utils/debounce';
import { vPopover,type PopoverOptions } from "@/directives/popover.directive"



const props = defineProps<{
  readyToRequest: boolean
}>()

const notificationAccess = usePermission('notifications');
const pushService = new PushService();
const notificationsSoftDisabled = ref<boolean>(false);
const showNotificationPrompt = ref<boolean>(false);
const showPopover = ref(false);
const popoverOptions: PopoverOptions = {
                                            title: 'Push Notifications disabled',
                                            content: 'To enable, click here!',
                                            placement: 'top',
                                            trigger: 'hover',
                                            show: showPopover
                                        };

function accept(){
    pushService.askPermission().then((result)=>{
        console.log(result);
        console.log("permission granted, proceed");
        showNotificationPrompt.value = false;
        notificationsSoftDisabled.value = false;
    });
}
function reject(){
    notificationsSoftDisabled.value = true;
    showNotificationPrompt.value = false;
    showPopover.value = true;
}

function reshowNotificationPrompt(){
    console.log("clicked");
    showNotificationPrompt.value = true;
}

const debouncedSubscribe = debounce(() => {
    console.log(`debounced Subscribe | ready: ${props.readyToRequest} | notificationAccess: ${notificationAccess.value} `)
        pushService.subscribeUserToPush();
}, 500)

watch([notificationAccess,() => props.readyToRequest], ([newNotificationAccess,newReadyToRequest], [oldNotificationAccess,oldReadyToRequest]) => {
    console.log(`watch notification triggered | ready: ${props.readyToRequest} | notificationAccess: ${notificationAccess.value} `)
        if(props.readyToRequest){
            if(notificationAccess.value == "granted"){
                debouncedSubscribe()
            }
        }
        if(oldNotificationAccess == "granted" && newNotificationAccess == "denied"){
            pushService.unsubscribe();
        }
    

    });
let modalIcon = computed<string>(()=>{
        if(permissionsBlocked) return 'fa-solid fa-bell-exclamation'
        return 'fa-solid fa-triangle-exclamation'
    });


let permissionsBlocked = computed<boolean>(()=>{
    console.log(`Permissions blocked: ${notificationAccess.value === 'denied'}`);
                return notificationAccess.value === 'denied';
    });

let showPermissionModal = computed<boolean>(()=>{
    console.log(`permissionNeeded: ${notificationAccess.value !== 'granted' && props.readyToRequest}`);
            return showNotificationPrompt.value || (notificationAccess.value !== 'granted' && props.readyToRequest && !notificationsSoftDisabled.value);
});

let showNotificationDisabled = computed<boolean>(()=>{
                return props.readyToRequest && notificationsSoftDisabled.value;
});


</script>