export function defined( x : any) : boolean{
    return x != undefined && x != null;
}

export function asString(x : string | null | undefined) : string{
    return x ?? ''
}

export function asNumber(x: number | null | undefined) : number{
    return x ?? 0
}
