/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissedCheckDto } from './MissedCheckDto';
import {
    MissedCheckDtoFromJSON,
    MissedCheckDtoFromJSONTyped,
    MissedCheckDtoToJSON,
} from './MissedCheckDto';

/**
 * 
 * @export
 * @interface GetSignOffPackByIdViewModel
 */
export interface GetSignOffPackByIdViewModel {
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    storeId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSignOffPackByIdViewModel
     */
    storeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSignOffPackByIdViewModel
     */
    packName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    retailWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    passedCheckCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    notApplicableCheckCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    missedCheckCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSignOffPackByIdViewModel
     */
    hasStoreManagerSignoff?: boolean;
    /**
     * 
     * @type {Array<MissedCheckDto>}
     * @memberof GetSignOffPackByIdViewModel
     */
    missedChecks?: Array<MissedCheckDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSignOffPackByIdViewModel
     */
    isAreaManager?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSignOffPackByIdViewModel
     */
    correctiveActionToCheckForSignOff?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSignOffPackByIdViewModel
     */
    userHasAccessToStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSignOffPackByIdViewModel
     */
    userCanSignOffPack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSignOffPackByIdViewModel
     */
    reasonCannotSignOff?: string | null;
}

/**
 * Check if a given object implements the GetSignOffPackByIdViewModel interface.
 */
export function instanceOfGetSignOffPackByIdViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetSignOffPackByIdViewModelFromJSON(json: any): GetSignOffPackByIdViewModel {
    return GetSignOffPackByIdViewModelFromJSONTyped(json, false);
}

export function GetSignOffPackByIdViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSignOffPackByIdViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storeId': !exists(json, 'storeId') ? undefined : json['storeId'],
        'storeName': !exists(json, 'storeName') ? undefined : json['storeName'],
        'packName': !exists(json, 'packName') ? undefined : json['packName'],
        'retailWeek': !exists(json, 'retailWeek') ? undefined : json['retailWeek'],
        'passedCheckCount': !exists(json, 'passedCheckCount') ? undefined : json['passedCheckCount'],
        'notApplicableCheckCount': !exists(json, 'notApplicableCheckCount') ? undefined : json['notApplicableCheckCount'],
        'missedCheckCount': !exists(json, 'missedCheckCount') ? undefined : json['missedCheckCount'],
        'hasStoreManagerSignoff': !exists(json, 'hasStoreManagerSignoff') ? undefined : json['hasStoreManagerSignoff'],
        'missedChecks': !exists(json, 'missedChecks') ? undefined : (json['missedChecks'] === null ? null : (json['missedChecks'] as Array<any>).map(MissedCheckDtoFromJSON)),
        'isAreaManager': !exists(json, 'isAreaManager') ? undefined : json['isAreaManager'],
        'correctiveActionToCheckForSignOff': !exists(json, 'correctiveActionToCheckForSignOff') ? undefined : json['correctiveActionToCheckForSignOff'],
        'userHasAccessToStore': !exists(json, 'userHasAccessToStore') ? undefined : json['userHasAccessToStore'],
        'userCanSignOffPack': !exists(json, 'userCanSignOffPack') ? undefined : json['userCanSignOffPack'],
        'reasonCannotSignOff': !exists(json, 'reasonCannotSignOff') ? undefined : json['reasonCannotSignOff'],
    };
}

export function GetSignOffPackByIdViewModelToJSON(value?: GetSignOffPackByIdViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storeId': value.storeId,
        'storeName': value.storeName,
        'packName': value.packName,
        'retailWeek': value.retailWeek,
        'passedCheckCount': value.passedCheckCount,
        'notApplicableCheckCount': value.notApplicableCheckCount,
        'missedCheckCount': value.missedCheckCount,
        'hasStoreManagerSignoff': value.hasStoreManagerSignoff,
        'missedChecks': value.missedChecks === undefined ? undefined : (value.missedChecks === null ? null : (value.missedChecks as Array<any>).map(MissedCheckDtoToJSON)),
        'isAreaManager': value.isAreaManager,
        'correctiveActionToCheckForSignOff': value.correctiveActionToCheckForSignOff,
        'userHasAccessToStore': value.userHasAccessToStore,
        'userCanSignOffPack': value.userCanSignOffPack,
        'reasonCannotSignOff': value.reasonCannotSignOff,
    };
}

