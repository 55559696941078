/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushNotificationUnsubscribeCommand
 */
export interface PushNotificationUnsubscribeCommand {
    /**
     * 
     * @type {string}
     * @memberof PushNotificationUnsubscribeCommand
     */
    endpoint?: string | null;
}

/**
 * Check if a given object implements the PushNotificationUnsubscribeCommand interface.
 */
export function instanceOfPushNotificationUnsubscribeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushNotificationUnsubscribeCommandFromJSON(json: any): PushNotificationUnsubscribeCommand {
    return PushNotificationUnsubscribeCommandFromJSONTyped(json, false);
}

export function PushNotificationUnsubscribeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushNotificationUnsubscribeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
    };
}

export function PushNotificationUnsubscribeCommandToJSON(value?: PushNotificationUnsubscribeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
    };
}

