<template>
    <div class="p-2 form-group">
        <div v-if="hasHelp" class="checkflow-help-box" :class="helpStyle" v-html="props.check.helpText" />
        <div class="pb-2" v-show="props.check.allowNotApplicable">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="chkIsNotApplicable" v-model="isNotApplicable">
                <label class="form-check-label" for="chkIsNotApplicable">
                    Not applicable for check / store.
                </label>
            </div>
        </div>

        <div v-if="showSupplimentaryQuestions">
        <div v-for="(question, index) in SupplementaryQuestions" :key="index">
            <div class="pb-2" style="font-weight: bold;">
                {{ question.text}}
            </div>

            <div class="pb-2">
                <SupplementaryQuestionValueEditor :type="question.expectedResponseType ?? ''" :initialValue="''" @selection-changed="onSelectionChanged(question, $event)" />
            </div>

            <div class="ps-2 checkflow-help" v-if="question.help !== undefined">
                <font-awesome-icon icon="fa-solid fa-circle-info" /> {{ question.help }}
            </div>
        </div>
        </div>
        <div v-show="last3Answers.length > 0">
            <div class="fw-bold">Last 3 Checks</div>
            <ul>
                <li v-for="(answer, index) in last3Answers" :key="index">{{ answer }}</li>                
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">

    import type { PackCheckDto, SupplementaryQuestionResponseDto, SupplementaryQuestionDto } from '@/HttpClient';
    import { ref, watch, getCurrentInstance, type ComponentInternalInstance, computed, onMounted } from 'vue'
    import SupplementaryQuestionValueEditor from '@/components/common/SupplementaryQuestionValueEditor.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { mapToHelpStyle } from '@/utils/styleMaps'
    interface Props {
        check: PackCheckDto
    }
    const props = defineProps<Props>()

    let SupplementaryQuestions = computed(() => props.check.supplementaryQuestions ?? []);
    let showSupplimentaryQuestions = computed(() => isNotApplicable.value ? false : SupplementaryQuestions.value.length > 0);
    let last3Answers = computed(() => props.check.lastThreeAnswers ?? []);

    let isNotApplicable = ref(false);
    let supplimentaryResponses = ref([] as Array<SupplementaryQuestionResponseDto>);

    watch(props.check, () => {
        resetSupplementaryResponses();
    })

    watch(isNotApplicable, () => {
        emit('is-not-applicable-changed', isNotApplicable.value);
    })

    const helpStyle = computed(() => mapToHelpStyle(props.check.colour));

    const hasHelp = computed(() => (props.check.helpText != ""));

    onMounted(() => resetSupplementaryResponses())

    function resetSupplementaryResponses() {
        supplimentaryResponses.value = [];
        isNotApplicable.value = false;
        (props.check.supplementaryQuestions ?? []).forEach(x =>
            supplimentaryResponses.value.push({
                supplementaryQuestionResponseId: x.supplementaryQuestionId,
                value: ""
            })
        )
    }
    // allow component to be reset
    const currentInstance = getCurrentInstance();
    if (currentInstance) {
        let keyRef = ref((currentInstance as ComponentInternalInstance).vnode.key)
        watch(keyRef, () => {
            resetSupplementaryResponses();
        })
    }

    const emit = defineEmits<{
        (e: 'responses-changed', data: Array<SupplementaryQuestionResponseDto>): void
        (e: 'is-not-applicable-changed', value: boolean): void
    }>()

    function onSelectionChanged(question: SupplementaryQuestionDto, data: string) {
        let selected = supplimentaryResponses.value.find(x => x.supplementaryQuestionResponseId == question.supplementaryQuestionId);
        if (!selected)
            return;
        selected.value = data;
        emit('responses-changed', supplimentaryResponses.value);
    }

</script>

<style>

    .checkflow-help {
        color: white;
        background-color: #03A9F4;
        border-radius: 0.5em;
        padding: 0.3em;
    }

    .checkflow-help-box {
        padding: 0.5em;
        margin-bottom: 0.5em;
        border-radius: 0.3em;
    }
</style>