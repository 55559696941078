import { httpClient } from "@/api/httpClient";
import { type Profile } from "@/models/application/profile";
import ApiResponse from "@/models/base/apiResponse";

export default class {
  private readonly resource = "/api/profile";

  async getProfile () : Promise<ApiResponse<Profile>> {
    const profileResponse = new ApiResponse<Profile>(async () => await httpClient.get(`${this.resource}`));
    await profileResponse.ready;
    return profileResponse;
  }
}
