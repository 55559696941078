/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissedCheckExplanationDto } from './MissedCheckExplanationDto';
import {
    MissedCheckExplanationDtoFromJSON,
    MissedCheckExplanationDtoFromJSONTyped,
    MissedCheckExplanationDtoToJSON,
} from './MissedCheckExplanationDto';

/**
 * 
 * @export
 * @interface GetMissedExplanationsVm
 */
export interface GetMissedExplanationsVm {
    /**
     * 
     * @type {Array<MissedCheckExplanationDto>}
     * @memberof GetMissedExplanationsVm
     */
    explanations?: Array<MissedCheckExplanationDto> | null;
}

/**
 * Check if a given object implements the GetMissedExplanationsVm interface.
 */
export function instanceOfGetMissedExplanationsVm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetMissedExplanationsVmFromJSON(json: any): GetMissedExplanationsVm {
    return GetMissedExplanationsVmFromJSONTyped(json, false);
}

export function GetMissedExplanationsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMissedExplanationsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'explanations': !exists(json, 'explanations') ? undefined : (json['explanations'] === null ? null : (json['explanations'] as Array<any>).map(MissedCheckExplanationDtoFromJSON)),
    };
}

export function GetMissedExplanationsVmToJSON(value?: GetMissedExplanationsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'explanations': value.explanations === undefined ? undefined : (value.explanations === null ? null : (value.explanations as Array<any>).map(MissedCheckExplanationDtoToJSON)),
    };
}

