<script setup lang="ts">
    import { useRouter } from "vue-router";

    const router = useRouter();

    function redirectToHomePage() {
        router.push({ name: "home" });
    }
</script>

<template>
    <div class="container py-4">
        <div class="p-5 mb-4 bg-light rounded-3 text-center">
            <h1 class="display-5 fw-bold">
                Access Refreshed
            </h1>
            <p class="col-md fs-4">
                All information used to decide your CheckFlow access has been cleared.  Make sure Nexus has been updated (transfers, training etc) before clicking the button below.
            </p>
            <button class="btn btn-primary btn-lg" @click="redirectToHomePage()">
                Select My Store
            </button>
        </div>
    </div>
</template>
