import { UserApi } from '@/HttpClient';
import { ApiConfig } from '@/config/apiConfig';
import User from "@/models/application/user";
import { useAuthStore } from "@/stores/authStore";

export default class AuthService {

    private readonly _authStore = useAuthStore();

    login(returnUrl: string) {
        window.location.href = `/auth/login?origin=${returnUrl}`;
    }

    logout() {
        window.location.href = "/auth/logout";
        this._authStore.$reset();
    }

    async getUser() {

        const api = new UserApi(new ApiConfig());

        try {
            let userResult = await api.apiUserGet();
            if(userResult.isAuthenticated) {
                const user = new User(
                    userResult?.name ?? "",
                    userResult.isAuthenticated,
                    userResult?.permissions ?? [],
                    userResult?.jobTitle ?? ""
                );
    
            if (user.isAuthenticated) {
                this._authStore.userDetails = user;
                this._authStore.lastAuthenticatedDate = new Date();
            }
        }
        } catch (error) {
            this._authStore.$reset();
        }
    }
}
