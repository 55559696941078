/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissedCheckExplanationDto
 */
export interface MissedCheckExplanationDto {
    /**
     * 
     * @type {number}
     * @memberof MissedCheckExplanationDto
     */
    explanationId?: number;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckExplanationDto
     */
    explanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissedCheckExplanationDto
     */
    programmaticKey?: string | null;
}

/**
 * Check if a given object implements the MissedCheckExplanationDto interface.
 */
export function instanceOfMissedCheckExplanationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MissedCheckExplanationDtoFromJSON(json: any): MissedCheckExplanationDto {
    return MissedCheckExplanationDtoFromJSONTyped(json, false);
}

export function MissedCheckExplanationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissedCheckExplanationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'explanationId': !exists(json, 'explanationId') ? undefined : json['explanationId'],
        'explanation': !exists(json, 'explanation') ? undefined : json['explanation'],
        'programmaticKey': !exists(json, 'programmaticKey') ? undefined : json['programmaticKey'],
    };
}

export function MissedCheckExplanationDtoToJSON(value?: MissedCheckExplanationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'explanationId': value.explanationId,
        'explanation': value.explanation,
        'programmaticKey': value.programmaticKey,
    };
}

