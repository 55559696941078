﻿import { format } from 'date-fns'
import { isToday } from 'date-fns'
import { addMinutes } from 'date-fns'
import isTomorrow from 'date-fns/isTomorrow/index';

export function formatPackCheckCompletionStatement(sectionName: string | null | undefined, dtFrom: Date | null | undefined, dtTo: Date | null | undefined, dtCheckedDate: Date | null | undefined, checkedByName: string | null | undefined): string {
    if (!dtTo || !dtFrom) return ""

    var formatted = "";

    switch (sectionName) {
        case "Upcoming":
            formatted = "Available from " + format(dtFrom, "HH:mm");
            break;
        case "Completed":
            if (dtCheckedDate) {
                formatted = "Checked by " + checkedByName + ' at ' + format(dtCheckedDate, "HH:mm");
            }
            break;
        default:
            formatted = "Complete by ";
            if (isToday(dtTo)) {
                formatted += format(dtTo, "HH:mm");
            }
            else if (isTomorrow(dtTo) && dtTo.getHours() == 0 && dtTo.getMinutes() == 0) {
                formatted += "end of day";
            }
            else {
                if (dtTo.getHours() == 0 && dtTo.getMinutes() == 0) {
                    formatted += `${format(addMinutes(dtTo, -1), "eeee")} (${format(addMinutes(dtTo, -1), "dd")}/${format(addMinutes(dtTo, -1), "MM")})`;
                }
                else {
                    formatted += `${format(addMinutes(dtTo, -1), "eeee")} (${format(addMinutes(dtTo, -1), "dd")}/${format(addMinutes(dtTo, -1), "MM")}) @ ${format(dtTo, "HH:mm")}`;
                }
            }
            break;
    }

    return formatted;
}

export function formatPackCheckMissedDescription(dtDue: Date | null | undefined, missedExplanation: string| null | undefined, dtCheckedDate: Date | null | undefined, checkedByName: string | null | undefined): string {
    if (!dtDue || !dtCheckedDate) return ""

    return `<small>Was due by ${format(dtDue, "dd/MM/yy HH:mm")} and completed ${format(dtCheckedDate, "dd/MM/yy HH:mm")} by ${checkedByName}. The reason for the delay was given as <i>${missedExplanation}</i>.</small>`;
}