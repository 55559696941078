/* tslint:disable */
/* eslint-disable */
/**
 * CheckFlow
 * CheckFlow
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetMissedExplanationsVm,
  GetResponsePackViewModel,
  GetResponsePacksViewModel,
} from '../models/index';
import {
    GetMissedExplanationsVmFromJSON,
    GetMissedExplanationsVmToJSON,
    GetResponsePackViewModelFromJSON,
    GetResponsePackViewModelToJSON,
    GetResponsePacksViewModelFromJSON,
    GetResponsePacksViewModelToJSON,
} from '../models/index';

export interface ApiResponsePackIdGetRequest {
    id: number;
}

export interface ApiResponsePackPackIdGetRequest {
    id: number;
}

/**
 * 
 */
export class ResponsePackApi extends runtime.BaseAPI {

    /**
     */
    async apiResponsePackIdGetRaw(requestParameters: ApiResponsePackIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetResponsePacksViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResponsePackIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ResponsePack/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsePacksViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiResponsePackIdGet(requestParameters: ApiResponsePackIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetResponsePacksViewModel> {
        const response = await this.apiResponsePackIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResponsePackMissedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMissedExplanationsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ResponsePack/missed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMissedExplanationsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiResponsePackMissedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMissedExplanationsVm> {
        const response = await this.apiResponsePackMissedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResponsePackPackIdGetRaw(requestParameters: ApiResponsePackPackIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetResponsePackViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResponsePackPackIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ResponsePack/pack/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResponsePackViewModelFromJSON(jsonValue));
    }

    /**
     */
    async apiResponsePackPackIdGet(requestParameters: ApiResponsePackPackIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetResponsePackViewModel> {
        const response = await this.apiResponsePackPackIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
